import React from "react";
import { Card } from "src/services/generatedApi";
/*
Card Display 

- strongly need to convert exisitng super components into smaller ones like this
*/
const CardDisplay = ({ card }: { card: Card }) => (
  <span>
    {card.cardholder_name} Card Ending {card.last_four}
  </span>
);

export default CardDisplay;
