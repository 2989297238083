import GlobalTheme from "src/theme";

const GlobalStyles = () => {
  const { fi_ui_customization } = GlobalTheme();

  const appStyles = {
    appBar: {
      paddingTop: "32px",
      paddingBottom: "13px",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    list: {
      width: "100%",
      paddingTop: "0",
    },
    list2: {
      width: "100%",
      paddingTop: "0",
    },
    icoTransfer: {
      width: "20px",
      marginTop: "12px",
    },
    icoTransfer2: {
      width: "33px",
      marginTop: "16px",
    },
    icoTransfer3: {
      width: "21px",
      marginTop: "12px",
    },
    circle: {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      fontSize: "50px",
      lineHeight: "25px",
    },
    circle2: {
      width: "80px",
      height: "80px",
      borderRadius: "50%",
      fontSize: "80px",
      lineHeight: "40px",
      margin: "16px auto 12px auto",
    },
    transferPendingBg: {
      height: "23px",
      width: "23px",
      background: fi_ui_customization.bg_medium,
      display: "inline-block",
      lineHeight: "24px",
      fontSize: "14px",
      margin: "0 8px",
    },
    incomingIcoBg: {
      background: fi_ui_customization.contrast2,
    },
    outgoingIcoBg: {
      background: fi_ui_customization.contrast1,
      border: `1px solid ${fi_ui_customization.bg_dark}`,
    },
    outgoingIcoBg2: {
      background: fi_ui_customization.contrast1,
      border: `1px solid ${fi_ui_customization.bg_dark}`,
      marginTop: "7px",
      marginLeft: "20px",
    },
    pendingIcoBg: {
      background: fi_ui_customization.contrast1,
      border: "none",
    },
    amount: {
      fontSize: "16px",
    },
    black: {
      color: fi_ui_customization.text_dark,
    },
    errorInput: {
      background: fi_ui_customization.text_dark,
      color: fi_ui_customization.bg_light,
      fontSize: "16px",
      padding: "20px",
    },
    green: {
      color: fi_ui_customization.contrast2,
    },
    fab: {
      background: "none",
      boxShadow: "none",
      width: "50px",
      height: "50px",
    },
    fab2: {
      background: "none",
      boxShadow: "none",
      width: "60px",
      height: "50px",
    },
    row: {
      padding: "24px 0",
    },
    row2: {
      padding: "24px 16px",
    },
    row3: {
      padding: "26px 16px 8px 16px",
    },
    title: {
      fontSize: "16px",
      color: fi_ui_customization.text_dark,
      fontWeight: 600,
    },
    bgDark: {
      background: fi_ui_customization.bg_dark,
    },
    bgMedium: {
      background: fi_ui_customization.bg_medium,
    },
    amountField: {
      fontSize: "40px",
      color: fi_ui_customization.text_dark,
      width: "140px",
      lineHeight: "100%",
    },
    amountSign: {
      fontSize: "40px",
      color: fi_ui_customization.text_dark,
      display: "inline",
    },
    inputRoot: {
      fontSize: 16,
      color: fi_ui_customization.text_dark,
      // borderColor: fi_ui_customization.text_medium,
      borderStyle: "solid",
      borderWidth: "0 0 .25px 0",
    },
    labelRoot: {
      fontSize: 16,
      fontStyle: "italic",
      color: fi_ui_customization.text_light,
    },
    triangleT: {
      display: "inline-block",
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderWidth: "0 10px 10px 10px",
      borderColor: `transparent transparent ${fi_ui_customization.text_dark} transparent`,
      top: "-10px",
    },
    triangleB: {
      display: "inline-block",
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderWidth: "10px 10px 0 10px",
      borderColor: `${fi_ui_customization.bg_light} transparent transparent transparent`,
      top: 0,
    },
    buttonHolder: {
      margin: "auto",
      padding: "5px 2em",
      maxWidth: "50em",
    },
  };

  return { appStyles };
};

export default GlobalStyles;
