import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { Grid, Typography, Fab } from "@mui/material";

import ico_list_2 from "src/assets/ico_list_2.svg";
import ico_home from "src/assets/ico_home.svg";
import { ROUTE_CONSTANTS } from "src/constants";

const { HISTORY_PAGE_PATH, SIGN_OUT_PATH, MAIN_PAGE_PATH } = ROUTE_CONSTANTS;

const EventDemoBar = ({ appStyles }: { appStyles: any }) => {
  const navigate = useNavigate();

  const [loginType] = useState(window.localStorage.getItem("loginType"));

  return (
    <Grid container style={appStyles.appBar}>
      <Grid item style={{ flexGrow: 5, position: "relative" }}>
        <Typography style={{ ...appStyles.title, padding: "15px 0 8px 0" }}>Send money</Typography>
      </Grid>
      <Grid item style={{ flexGrow: 1 }}>
        <Fab
          aria-label="History"
          style={{ ...appStyles.fab, float: "right", marginRight: "10px" }}
          onClick={() => navigate(HISTORY_PAGE_PATH)}
        >
          <img style={{ marginTop: "8px" }} src={ico_list_2} alt="History icon" />
        </Fab>
        <Fab
          aria-label="History"
          style={{ ...appStyles.fab, float: "right", margin: "-3px 10px 0 0" }}
          onClick={() => navigate(MAIN_PAGE_PATH)}
        >
          <img style={{ marginTop: "8px" }} src={ico_home} alt="Home icon" />
        </Fab>
        {loginType == "sso" ? null : (
          <Fab
            aria-label="Log out"
            style={{ ...appStyles.fab2, float: "right", marginRight: "10px", width: "100px" }}
            onClick={() => navigate(SIGN_OUT_PATH)}
          >
            Log Out
          </Fab>
        )}
      </Grid>
    </Grid>
  );
};

export default EventDemoBar;
