/* tslint:disable */
/* eslint-disable */
/**
 * Neural Payments Embedded API
 * API documentation for Neural Payments embedded API.  This API is used by Embedded web app and in Mobile Banking Provider API integrations. You can use this API in client-to-server or server-to-server applications.  Authentication  - server-to-server   - You will need to generate a JWT to be used as a Bearer token - client-to-server   - You will need to follow the steps above. But, instead of using the JWT as a bearer token exchange it for a cookie at the /session/sso endpoint.
 *
 * The version of the OpenAPI document: 0.5.2
 * Contact: engineering@neuralpayments.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "./configuration";
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
import type { RequestArgs } from "./base";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from "./base";

/**
 *
 * @export
 * @interface Account
 */
export interface Account {
  /**
   * Account\'s id (matches account_id elsewhere in this API)
   * @type {string}
   * @memberof Account
   * @deprecated
   */
  id: string;
  /**
   * Account\'s unique id
   * @type {string}
   * @memberof Account
   */
  uuid: string;
  /**
   * DDA for account
   * @type {string}
   * @memberof Account
   */
  dda: string;
  /**
   * Name for account
   * @type {string}
   * @memberof Account
   */
  name: string;
  /**
   * account balance
   * @type {string}
   * @memberof Account
   */
  balance?: string;
  /**
   * Type of account (e.g. Checking = CK, Savings = SV)
   * @type {string}
   * @memberof Account
   */
  code: string;
  /**
   * Corresponding internal card ids
   * @type {Array<string>}
   * @memberof Account
   */
  card_uuids: Array<string>;
}
/**
 *
 * @export
 * @interface AccountChanges
 */
export interface AccountChanges {
  /**
   * The timestamp of the account changes event
   * @type {number}
   * @memberof AccountChanges
   */
  timestamp: number;
  /**
   * Name of the identifier value where the account change occured
   * @type {string}
   * @memberof AccountChanges
   */
  identifier_value: string;
}
/**
 *
 * @export
 * @interface Activity
 */
export interface Activity {
  /**
   * The app id
   * @type {string}
   * @memberof Activity
   */
  actor?: string;
  /**
   * The api key
   * @type {string}
   * @memberof Activity
   */
  data: string;
  /**
   * The token
   * @type {string}
   * @memberof Activity
   */
  foregn_id?: string;
  /**
   * The feed
   * @type {string}
   * @memberof Activity
   */
  id?: string;
  /**
   * The userid
   * @type {string}
   * @memberof Activity
   */
  object?: string;
  /**
   * The userid
   * @type {string}
   * @memberof Activity
   */
  origin?: string;
  /**
   * The userid
   * @type {string}
   * @memberof Activity
   */
  target?: string;
  /**
   * The userid
   * @type {string}
   * @memberof Activity
   */
  time?: string;
  /**
   * The userid
   * @type {string}
   * @memberof Activity
   */
  verb: string;
}
/**
 *
 * @export
 * @interface ActivityGroup
 */
export interface ActivityGroup {
  /**
   * A List of activities for this grouping.
   * @type {Array<Activity>}
   * @memberof ActivityGroup
   */
  activities?: Array<Activity>;
  /**
   * Number of activities in this group.
   * @type {number}
   * @memberof ActivityGroup
   */
  activity_count?: number;
  /**
   * Number of activities in this group.
   * @type {number}
   * @memberof ActivityGroup
   */
  actor_count?: number;
  /**
   * Time this group was created.
   * @type {string}
   * @memberof ActivityGroup
   */
  created_at?: string;
  /**
   * The name of the activity group. Its naming convention follows the verb_date format. Example (transfer_status_2022-10-24)
   * @type {string}
   * @memberof ActivityGroup
   */
  group?: string;
  /**
   * Id of this activity group.
   * @type {string}
   * @memberof ActivityGroup
   */
  id?: string;
  /**
   * If this notification group has been read before.
   * @type {boolean}
   * @memberof ActivityGroup
   */
  is_read?: boolean;
  /**
   * If this notification group has been seen before.
   * @type {boolean}
   * @memberof ActivityGroup
   */
  is_seen?: boolean;
  /**
   * Last updated time
   * @type {string}
   * @memberof ActivityGroup
   */
  updated_at?: string;
  /**
   * The type of event contained in this groups activities. This field is important.
   * @type {string}
   * @memberof ActivityGroup
   */
  verb?: string;
}
/**
 *
 * @export
 * @interface Card
 */
export interface Card {
  /**
   * Card\'s unique id
   * @type {string}
   * @memberof Card
   */
  uuid: string;
  /**
   * Last four characters in the card number
   * @type {string}
   * @memberof Card
   */
  last_four: string;
  /**
   * Corresponding internal account ids
   * @type {Array<string>}
   * @memberof Card
   */
  account_uuids: Array<string>;
  /**
   * Card holder name
   * @type {string}
   * @memberof Card
   */
  cardholder_name: string;
}
/**
 *
 * @export
 * @interface CreateSsoSession400Response
 */
export interface CreateSsoSession400Response {
  /**
   * HTTP Status code
   * @type {number}
   * @memberof CreateSsoSession400Response
   */
  status: number;
  /**
   * Error\'s type URI reference
   * @type {string}
   * @memberof CreateSsoSession400Response
   */
  type: string;
  /**
   * Error summary
   * @type {string}
   * @memberof CreateSsoSession400Response
   */
  title: string;
  /**
   * Error explanation
   * @type {string}
   * @memberof CreateSsoSession400Response
   */
  detail: string;
}
/**
 *
 * @export
 * @interface CreateSsoSession404Response
 */
export interface CreateSsoSession404Response {
  /**
   * HTTP Status code
   * @type {number}
   * @memberof CreateSsoSession404Response
   */
  status: number;
  /**
   * Error\'s type URI reference
   * @type {string}
   * @memberof CreateSsoSession404Response
   */
  type: string;
  /**
   * Error summary
   * @type {string}
   * @memberof CreateSsoSession404Response
   */
  title: string;
  /**
   * Error explanation
   * @type {string}
   * @memberof CreateSsoSession404Response
   */
  detail: string;
}
/**
 *
 * @export
 * @interface CreateSsoSession422Response
 */
export interface CreateSsoSession422Response {
  /**
   * HTTP Status code
   * @type {number}
   * @memberof CreateSsoSession422Response
   */
  status: number;
  /**
   * Error\'s type URI reference
   * @type {string}
   * @memberof CreateSsoSession422Response
   */
  type: string;
  /**
   * Error summary
   * @type {string}
   * @memberof CreateSsoSession422Response
   */
  title: string;
  /**
   * Error explanation
   * @type {string}
   * @memberof CreateSsoSession422Response
   */
  detail: string;
}
/**
 *
 * @export
 * @interface DestroySession401Response
 */
export interface DestroySession401Response {
  /**
   * HTTP Status code
   * @type {number}
   * @memberof DestroySession401Response
   */
  status: number;
  /**
   * Error\'s type URI reference
   * @type {string}
   * @memberof DestroySession401Response
   */
  type: string;
  /**
   * Error summary
   * @type {string}
   * @memberof DestroySession401Response
   */
  title: string;
  /**
   * Error explanation
   * @type {string}
   * @memberof DestroySession401Response
   */
  detail: string;
}
/**
 *
 * @export
 * @interface DestroySession403Response
 */
export interface DestroySession403Response {
  /**
   * HTTP Status code
   * @type {number}
   * @memberof DestroySession403Response
   */
  status: number;
  /**
   * Error\'s type URI reference
   * @type {string}
   * @memberof DestroySession403Response
   */
  type: string;
  /**
   * Error summary
   * @type {string}
   * @memberof DestroySession403Response
   */
  title: string;
  /**
   * Error explanation
   * @type {string}
   * @memberof DestroySession403Response
   */
  detail: string;
}
/**
 *
 * @export
 * @interface DestroySession500Response
 */
export interface DestroySession500Response {
  /**
   * HTTP Status code
   * @type {number}
   * @memberof DestroySession500Response
   */
  status: number;
  /**
   * Error\'s type URI reference
   * @type {string}
   * @memberof DestroySession500Response
   */
  type: string;
  /**
   * Error summary
   * @type {string}
   * @memberof DestroySession500Response
   */
  title: string;
  /**
   * Error explanation
   * @type {string}
   * @memberof DestroySession500Response
   */
  detail: string;
}
/**
 *
 * @export
 * @interface FiUiCustomization
 */
export interface FiUiCustomization {
  /**
   * FI bg light color
   * @type {string}
   * @memberof FiUiCustomization
   */
  bg_light: string;
  /**
   * FI bg medium color
   * @type {string}
   * @memberof FiUiCustomization
   */
  bg_medium: string;
  /**
   * FI bg dark color
   * @type {string}
   * @memberof FiUiCustomization
   */
  bg_dark: string;
  /**
   * FI text light color
   * @type {string}
   * @memberof FiUiCustomization
   */
  text_light: string;
  /**
   * FI text medium color
   * @type {string}
   * @memberof FiUiCustomization
   */
  text_medium: string;
  /**
   * FI text dark color
   * @type {string}
   * @memberof FiUiCustomization
   */
  text_dark: string;
  /**
   * FI button disabled color
   * @type {string}
   * @memberof FiUiCustomization
   */
  button_disabled: string;
  /**
   * FI button enabled color
   * @type {string}
   * @memberof FiUiCustomization
   */
  button_enabled: string;
  /**
   * FI auto send text
   * @type {boolean}
   * @memberof FiUiCustomization
   */
  send_on_network_notification: boolean;
  /**
   * FI auto send text
   * @type {object}
   * @memberof FiUiCustomization
   */
  sms_customization: object;
  /**
   * FI off network bg primary color
   * @type {string}
   * @memberof FiUiCustomization
   */
  off_network_bg_primary: string;
  /**
   * FI off network bg secondary color
   * @type {string}
   * @memberof FiUiCustomization
   */
  off_network_bg_secondary: string;
  /**
   * FI off network button color
   * @type {string}
   * @memberof FiUiCustomization
   */
  off_network_button_color: string;
  /**
   * FI off network text color
   * @type {string}
   * @memberof FiUiCustomization
   */
  off_network_text_color: string;
  /**
   * Event demo enabled
   * @type {boolean}
   * @memberof FiUiCustomization
   */
  event_demo: boolean;
}
/**
 *
 * @export
 * @interface Identifier
 */
export interface Identifier {
  /**
   * Type of identifier, one of `email` or `phone`
   * @type {string}
   * @memberof Identifier
   */
  type: string;
  /**
   * The email address or phone number
   * @type {string}
   * @memberof Identifier
   */
  value: string;
  /**
   * The default card uuid
   * @type {string}
   * @memberof Identifier
   */
  default_card?: string;
}
/**
 *
 * @export
 * @interface IncomingTransfer
 */
export interface IncomingTransfer {
  /**
   * The transfer id
   * @type {string}
   * @memberof IncomingTransfer
   */
  id: string;
  /**
   * The timestamp of the transfer (seconds since epoch)
   * @type {number}
   * @memberof IncomingTransfer
   */
  timestamp: number;
  /**
   * The name of the sender
   * @type {string}
   * @memberof IncomingTransfer
   */
  sender: string;
  /**
   * The account id
   * @type {string}
   * @memberof IncomingTransfer
   */
  account: string;
  /**
   *
   * @type {IncomingTransferAmount}
   * @memberof IncomingTransfer
   */
  amount: IncomingTransferAmount;
  /**
   *
   * @type {IncomingTransferRecipient}
   * @memberof IncomingTransfer
   */
  recipient: IncomingTransferRecipient;
  /**
   * A description of the transfer visible to the receiver
   * @type {string}
   * @memberof IncomingTransfer
   */
  note: string;
}
/**
 * The amount to transfer
 * @export
 * @interface IncomingTransferAmount
 */
export interface IncomingTransferAmount {
  /**
   * The currency type (currently only supported value is \'USD\')
   * @type {string}
   * @memberof IncomingTransferAmount
   */
  type: string;
  /**
   * The amount value to transfer
   * @type {string}
   * @memberof IncomingTransferAmount
   */
  value: string;
}
/**
 * The recipient
 * @export
 * @interface IncomingTransferRecipient
 */
export interface IncomingTransferRecipient {
  /**
   * The recipient type (currently only supported values are \'email\' and \'phone\')
   * @type {string}
   * @memberof IncomingTransferRecipient
   */
  type: string;
  /**
   * The email address or phone number
   * @type {string}
   * @memberof IncomingTransferRecipient
   */
  value: string;
}
/**
 *
 * @export
 * @interface NewTransfer
 */
export interface NewTransfer {
  /**
   * The account id
   * @type {string}
   * @memberof NewTransfer
   */
  account: string;
  /**
   * The bank card identifier. When an account exists on multiple accounts (e.g. joint accounts), provide this optional value to specify a card. Otherwise, the specific account and card will be determined by the backend.
   * @type {string}
   * @memberof NewTransfer
   */
  card_uuid?: string;
  /**
   *
   * @type {IncomingTransferAmount}
   * @memberof NewTransfer
   */
  amount: IncomingTransferAmount;
  /**
   * The recipient identifiers
   * @type {Array<NewTransferRecipientInner>}
   * @memberof NewTransfer
   */
  recipient: Array<NewTransferRecipientInner>;
  /**
   * A description of the transfer visible to the receiver
   * @type {string}
   * @memberof NewTransfer
   */
  note: string;
}
/**
 * a recipient identifier
 * @export
 * @interface NewTransferRecipientInner
 */
export interface NewTransferRecipientInner {
  /**
   * The recipient type (currently only supported values are \'email\' and \'phone\')
   * @type {string}
   * @memberof NewTransferRecipientInner
   */
  type: string;
  /**
   * The email address or phone number
   * @type {string}
   * @memberof NewTransferRecipientInner
   */
  value: string;
}
/**
 *
 * @export
 * @interface OutgoingTransfer
 */
export interface OutgoingTransfer {
  /**
   * The transfer id
   * @type {string}
   * @memberof OutgoingTransfer
   */
  id: string;
  /**
   * The timestamp of the transfer (seconds since epoch)
   * @type {number}
   * @memberof OutgoingTransfer
   */
  timestamp: number;
  /**
   * The current status of the transfer (succeeded, pending, failed)
   * @type {string}
   * @memberof OutgoingTransfer
   */
  status: string;
  /**
   * The account id
   * @type {string}
   * @memberof OutgoingTransfer
   */
  account: string;
  /**
   *
   * @type {IncomingTransferAmount}
   * @memberof OutgoingTransfer
   */
  amount: IncomingTransferAmount;
  /**
   *
   * @type {IncomingTransferRecipient}
   * @memberof OutgoingTransfer
   */
  recipient: IncomingTransferRecipient;
  /**
   * A description of the transfer visible to the receiver
   * @type {string}
   * @memberof OutgoingTransfer
   */
  note: string;
}
/**
 *
 * @export
 * @interface SsoSession
 */
export interface SsoSession {
  /**
   * A JWT received from the Neural Payments auth server. These can be genrated on a successful SSO login or through a valid token exchange.
   * @type {string}
   * @memberof SsoSession
   */
  token: string;
}
/**
 *
 * @export
 * @interface Stream
 */
export interface Stream {
  /**
   * The app id
   * @type {string}
   * @memberof Stream
   */
  appid: string;
  /**
   * The api key
   * @type {string}
   * @memberof Stream
   */
  apikey: string;
  /**
   * The token
   * @type {string}
   * @memberof Stream
   */
  token: string;
  /**
   * The feed
   * @type {string}
   * @memberof Stream
   */
  feed: string;
  /**
   * The userid
   * @type {string}
   * @memberof Stream
   */
  userid: string;
}
/**
 *
 * @export
 * @interface StreamFeed
 */
export interface StreamFeed {
  /**
   * An array of activity groups. Activities are grouped by verb by day.
   * @type {Array<ActivityGroup>}
   * @memberof StreamFeed
   */
  results: Array<ActivityGroup>;
  /**
   * How long it took to return a response. This field can be ignored.
   * @type {string}
   * @memberof StreamFeed
   */
  duration: string;
  /**
   * The amount of unseen notifications you have. Will be marked as read after fetch. This field can be ignored.
   * @type {number}
   * @memberof StreamFeed
   */
  unseen: number;
  /**
   * The amount of unread notifications you have. Will be marked as read after fetch. This field can be ignored.
   * @type {number}
   * @memberof StreamFeed
   */
  unread: number;
}
/**
 *
 * @export
 * @interface TransferHistory
 */
export interface TransferHistory {
  /**
   * Array of most recent transfers returned for this query
   * @type {Array<TransferHistoryItem>}
   * @memberof TransferHistory
   */
  data: Array<TransferHistoryItem>;
  /**
   * The number of TransferHistoryItems returned on the current page
   * @type {number}
   * @memberof TransferHistory
   */
  length: number;
  /**
   * The maximum number of TransferHistoryItems returned on a page
   * @type {number}
   * @memberof TransferHistory
   */
  limit: number;
  /**
   * The current page number
   * @type {number}
   * @memberof TransferHistory
   */
  page: number;
  /**
   * The total number of TransferHistoryItems returned
   * @type {number}
   * @memberof TransferHistory
   */
  total: number;
}
/**
 *
 * @export
 * @interface TransferHistoryItem
 */
export interface TransferHistoryItem {
  /**
   * The transfer id
   * @type {string}
   * @memberof TransferHistoryItem
   */
  id: string;
  /**
   * The account id the funds were taken from or added to.
   * @type {string}
   * @memberof TransferHistoryItem
   */
  account: string;
  /**
   *
   * @type {IncomingTransferAmount}
   * @memberof TransferHistoryItem
   */
  amount: IncomingTransferAmount;
  /**
   * A description of the transfer visible to the receiver
   * @type {string}
   * @memberof TransferHistoryItem
   */
  note: string;
  /**
   * If the transfer is on/off network. If a transfer is off network it means the receiver is not within our database.
   * @type {boolean}
   * @memberof TransferHistoryItem
   */
  on_network: boolean;
  /**
   * If the transfer originated from one of this users accounts.
   * @type {boolean}
   * @memberof TransferHistoryItem
   */
  outgoing: boolean;
  /**
   *
   * @type {IncomingTransferRecipient}
   * @memberof TransferHistoryItem
   */
  recipient: IncomingTransferRecipient;
  /**
   * The name of the user who sent the funds
   * @type {string}
   * @memberof TransferHistoryItem
   */
  sender_name: string;
  /**
   * The date and time of the transfer
   * @type {string}
   * @memberof TransferHistoryItem
   */
  timestamp: string;
  /**
   * The current status of the transfer (SUCCEEDED, PENDING, FAILED)
   * @type {string}
   * @memberof TransferHistoryItem
   */
  status: string;
  /**
   * If the transfer is cancellable
   * @type {boolean}
   * @memberof TransferHistoryItem
   */
  is_cancellable: boolean;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get account changes
     * @summary Get latest account changes for which the users identifier was changed to a new financial institution
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountChanges: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/account/changes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CookieAuth required

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get accounts
     * @summary Get bank accounts associated with the Mobile Banking User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccounts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/account`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CookieAuth required

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns any of this user\'s identifiers (email/phone) for which this account is the default destination for funds transferred on the Neural Payments network
     * @summary Get default network account status
     * @param {string} accountId The unique id of the account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDefaultNetworkAccount: async (accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists("getDefaultNetworkAccount", "accountId", accountId);
      const localVarPath = `/account/{account_id}/default`.replace(
        `{${"account_id"}}`,
        encodeURIComponent(String(accountId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CookieAuth required

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Mark this account to be the default destination for funds transferred on the Neural Payments network to the given identifier
     * @summary Set default network account status
     * @param {string} accountId The unique id of the account
     * @param {string} identifierType The type of the identifier
     * @param {string} identifierValue The value of the identifier
     * @param {string} [defaultCard] The uuid of the default card
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setDefaultNetworkAccount: async (
      accountId: string,
      identifierType: string,
      identifierValue: string,
      defaultCard?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists("setDefaultNetworkAccount", "accountId", accountId);
      // verify required parameter 'identifierType' is not null or undefined
      assertParamExists("setDefaultNetworkAccount", "identifierType", identifierType);
      // verify required parameter 'identifierValue' is not null or undefined
      assertParamExists("setDefaultNetworkAccount", "identifierValue", identifierValue);
      const localVarPath = `/account/{account_id}/default/{identifier_type}/{identifier_value}`
        .replace(`{${"account_id"}}`, encodeURIComponent(String(accountId)))
        .replace(`{${"identifier_type"}}`, encodeURIComponent(String(identifierType)))
        .replace(`{${"identifier_value"}}`, encodeURIComponent(String(identifierValue)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CookieAuth required

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (defaultCard !== undefined) {
        localVarQueryParameter["default_card"] = defaultCard;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration);
  return {
    /**
     * Get account changes
     * @summary Get latest account changes for which the users identifier was changed to a new financial institution
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAccountChanges(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountChanges>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountChanges(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AccountApi.getAccountChanges"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get accounts
     * @summary Get bank accounts associated with the Mobile Banking User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAccounts(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Account>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAccounts(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AccountApi.getAccounts"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Returns any of this user\'s identifiers (email/phone) for which this account is the default destination for funds transferred on the Neural Payments network
     * @summary Get default network account status
     * @param {string} accountId The unique id of the account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDefaultNetworkAccount(
      accountId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Identifier>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultNetworkAccount(accountId, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AccountApi.getDefaultNetworkAccount"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Mark this account to be the default destination for funds transferred on the Neural Payments network to the given identifier
     * @summary Set default network account status
     * @param {string} accountId The unique id of the account
     * @param {string} identifierType The type of the identifier
     * @param {string} identifierValue The value of the identifier
     * @param {string} [defaultCard] The uuid of the default card
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setDefaultNetworkAccount(
      accountId: string,
      identifierType: string,
      identifierValue: string,
      defaultCard?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Identifier>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setDefaultNetworkAccount(
        accountId,
        identifierType,
        identifierValue,
        defaultCard,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AccountApi.setDefaultNetworkAccount"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AccountApiFp(configuration);
  return {
    /**
     * Get account changes
     * @summary Get latest account changes for which the users identifier was changed to a new financial institution
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountChanges(options?: any): AxiosPromise<Array<AccountChanges>> {
      return localVarFp.getAccountChanges(options).then((request) => request(axios, basePath));
    },
    /**
     * Get accounts
     * @summary Get bank accounts associated with the Mobile Banking User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccounts(options?: any): AxiosPromise<Array<Account>> {
      return localVarFp.getAccounts(options).then((request) => request(axios, basePath));
    },
    /**
     * Returns any of this user\'s identifiers (email/phone) for which this account is the default destination for funds transferred on the Neural Payments network
     * @summary Get default network account status
     * @param {string} accountId The unique id of the account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDefaultNetworkAccount(accountId: string, options?: any): AxiosPromise<Array<Identifier>> {
      return localVarFp.getDefaultNetworkAccount(accountId, options).then((request) => request(axios, basePath));
    },
    /**
     * Mark this account to be the default destination for funds transferred on the Neural Payments network to the given identifier
     * @summary Set default network account status
     * @param {string} accountId The unique id of the account
     * @param {string} identifierType The type of the identifier
     * @param {string} identifierValue The value of the identifier
     * @param {string} [defaultCard] The uuid of the default card
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setDefaultNetworkAccount(
      accountId: string,
      identifierType: string,
      identifierValue: string,
      defaultCard?: string,
      options?: any,
    ): AxiosPromise<Identifier> {
      return localVarFp
        .setDefaultNetworkAccount(accountId, identifierType, identifierValue, defaultCard, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
  /**
   * Get account changes
   * @summary Get latest account changes for which the users identifier was changed to a new financial institution
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public getAccountChanges(options?: RawAxiosRequestConfig) {
    return AccountApiFp(this.configuration)
      .getAccountChanges(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get accounts
   * @summary Get bank accounts associated with the Mobile Banking User
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public getAccounts(options?: RawAxiosRequestConfig) {
    return AccountApiFp(this.configuration)
      .getAccounts(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns any of this user\'s identifiers (email/phone) for which this account is the default destination for funds transferred on the Neural Payments network
   * @summary Get default network account status
   * @param {string} accountId The unique id of the account
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public getDefaultNetworkAccount(accountId: string, options?: RawAxiosRequestConfig) {
    return AccountApiFp(this.configuration)
      .getDefaultNetworkAccount(accountId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Mark this account to be the default destination for funds transferred on the Neural Payments network to the given identifier
   * @summary Set default network account status
   * @param {string} accountId The unique id of the account
   * @param {string} identifierType The type of the identifier
   * @param {string} identifierValue The value of the identifier
   * @param {string} [defaultCard] The uuid of the default card
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public setDefaultNetworkAccount(
    accountId: string,
    identifierType: string,
    identifierValue: string,
    defaultCard?: string,
    options?: RawAxiosRequestConfig,
  ) {
    return AccountApiFp(this.configuration)
      .setDefaultNetworkAccount(accountId, identifierType, identifierValue, defaultCard, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CardApi - axios parameter creator
 * @export
 */
export const CardApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get cards
     * @summary Get bank cards associated with the Mobile Banking User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCards: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/card`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CookieAuth required

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CardApi - functional programming interface
 * @export
 */
export const CardApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CardApiAxiosParamCreator(configuration);
  return {
    /**
     * Get cards
     * @summary Get bank cards associated with the Mobile Banking User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCards(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Card>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCards(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["CardApi.getCards"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * CardApi - factory interface
 * @export
 */
export const CardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CardApiFp(configuration);
  return {
    /**
     * Get cards
     * @summary Get bank cards associated with the Mobile Banking User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCards(options?: any): AxiosPromise<Array<Card>> {
      return localVarFp.getCards(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * CardApi - object-oriented interface
 * @export
 * @class CardApi
 * @extends {BaseAPI}
 */
export class CardApi extends BaseAPI {
  /**
   * Get cards
   * @summary Get bank cards associated with the Mobile Banking User
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CardApi
   */
  public getCards(options?: RawAxiosRequestConfig) {
    return CardApiFp(this.configuration)
      .getCards(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FiUiCustomizationApi - axios parameter creator
 * @export
 */
export const FiUiCustomizationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get fi ui customization
     * @summary Get ui customization associated with the Financial Institution
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUi: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/ui`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CookieAuth required

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FiUiCustomizationApi - functional programming interface
 * @export
 */
export const FiUiCustomizationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FiUiCustomizationApiAxiosParamCreator(configuration);
  return {
    /**
     * Get fi ui customization
     * @summary Get ui customization associated with the Financial Institution
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUi(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FiUiCustomization>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUi(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["FiUiCustomizationApi.getUi"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * FiUiCustomizationApi - factory interface
 * @export
 */
export const FiUiCustomizationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FiUiCustomizationApiFp(configuration);
  return {
    /**
     * Get fi ui customization
     * @summary Get ui customization associated with the Financial Institution
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUi(options?: any): AxiosPromise<FiUiCustomization> {
      return localVarFp.getUi(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * FiUiCustomizationApi - object-oriented interface
 * @export
 * @class FiUiCustomizationApi
 * @extends {BaseAPI}
 */
export class FiUiCustomizationApi extends BaseAPI {
  /**
   * Get fi ui customization
   * @summary Get ui customization associated with the Financial Institution
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FiUiCustomizationApi
   */
  public getUi(options?: RawAxiosRequestConfig) {
    return FiUiCustomizationApiFp(this.configuration)
      .getUi(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * IdentifierApi - axios parameter creator
 * @export
 */
export const IdentifierApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get the identifiers (email addresses and phone numbers) that are associated with a Mobile Banking User
     * @summary Get identifiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserIdentifiers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/identifier`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CookieAuth required

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * IdentifierApi - functional programming interface
 * @export
 */
export const IdentifierApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = IdentifierApiAxiosParamCreator(configuration);
  return {
    /**
     * Get the identifiers (email addresses and phone numbers) that are associated with a Mobile Banking User
     * @summary Get identifiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserIdentifiers(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Identifier>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserIdentifiers(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["IdentifierApi.getUserIdentifiers"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * IdentifierApi - factory interface
 * @export
 */
export const IdentifierApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = IdentifierApiFp(configuration);
  return {
    /**
     * Get the identifiers (email addresses and phone numbers) that are associated with a Mobile Banking User
     * @summary Get identifiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserIdentifiers(options?: any): AxiosPromise<Array<Identifier>> {
      return localVarFp.getUserIdentifiers(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * IdentifierApi - object-oriented interface
 * @export
 * @class IdentifierApi
 * @extends {BaseAPI}
 */
export class IdentifierApi extends BaseAPI {
  /**
   * Get the identifiers (email addresses and phone numbers) that are associated with a Mobile Banking User
   * @summary Get identifiers
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IdentifierApi
   */
  public getUserIdentifiers(options?: RawAxiosRequestConfig) {
    return IdentifierApiFp(this.configuration)
      .getUserIdentifiers(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Gets the notification stream credentials for a user. Once retrieved these credentials can be used with a getstream.io client to fetch notifications for a transfer. Notifications gives you real time data about the transfer. For example - it the transfer succeeded or failed.
     * @summary Get notification stream credentials for user.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getNotificationStream: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/notification/stream`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CookieAuth required

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns the users notification feed. This feed can be used to view app & transfer events such as the `transfer_status` (success/failure). Or, it its an off network transfer you can view the `shortlink_generated`, and more. This endpoint allows you to get the getstream notifcation feed without having to use getstream client locally.
     * @summary Returns the notification feed for the authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStreamFeed: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/notification/feed`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CookieAuth required

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint will return notification data for a specific transfer. - **Polling for response**   - Once a transfer is initated using the `POST /transfer/outgoing` endpoint you will need to poll for the response. The goal here is to see if the transfer was successful or if you need to take any additional steps (for example - send the transfer instructions). If you are viewing a transfer in the pending state you can also refetch the instructions if you want the user to send the transfer instructions again.   - If you dont find any notifications in the returned array you will need to wait a second and try again.    - We usually attempt this up to 15 (with a 1 second pause) times before showing an error to the user - telling the user to check back later for the status of the transfer. Its important to note that you should recieve a successful response most of the time.  - **Responses**   - This endpoint will return an array of responses. Depending on the type of response you will need to pass on different actions to the user.   - Each response will contain an array of objects. Each object represents a different type of notification for a transfer. This notification type can be found on the objects `verb`.     - Types       - transfer_status         - Tells you if a transfer was successful or not. If it was successful there is nothing else you need to do.       - shortlink_generated         - This notification type is created during off network transfers. If the user sends funds to a phone number you will need to get the shortlink from this notifications data. Once you have that you can prompt them to send a text containing the transfer instructions.   - This response will contain data about the transfer such as if it completed successfully, and if there are any more actions you need to take.
     * @summary Returns the notifications for a specific transfer.
     * @param {string} transferId The unique id of the transfer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransferFeed: async (transferId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'transferId' is not null or undefined
      assertParamExists("getTransferFeed", "transferId", transferId);
      const localVarPath = `/notification/transfer/{transfer_id}`.replace(
        `{${"transfer_id"}}`,
        encodeURIComponent(String(transferId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CookieAuth required

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration);
  return {
    /**
     * Gets the notification stream credentials for a user. Once retrieved these credentials can be used with a getstream.io client to fetch notifications for a transfer. Notifications gives you real time data about the transfer. For example - it the transfer succeeded or failed.
     * @summary Get notification stream credentials for user.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async getNotificationStream(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Stream>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationStream(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["NotificationApi.getNotificationStream"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Returns the users notification feed. This feed can be used to view app & transfer events such as the `transfer_status` (success/failure). Or, it its an off network transfer you can view the `shortlink_generated`, and more. This endpoint allows you to get the getstream notifcation feed without having to use getstream client locally.
     * @summary Returns the notification feed for the authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStreamFeed(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StreamFeed>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStreamFeed(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["NotificationApi.getStreamFeed"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * This endpoint will return notification data for a specific transfer. - **Polling for response**   - Once a transfer is initated using the `POST /transfer/outgoing` endpoint you will need to poll for the response. The goal here is to see if the transfer was successful or if you need to take any additional steps (for example - send the transfer instructions). If you are viewing a transfer in the pending state you can also refetch the instructions if you want the user to send the transfer instructions again.   - If you dont find any notifications in the returned array you will need to wait a second and try again.    - We usually attempt this up to 15 (with a 1 second pause) times before showing an error to the user - telling the user to check back later for the status of the transfer. Its important to note that you should recieve a successful response most of the time.  - **Responses**   - This endpoint will return an array of responses. Depending on the type of response you will need to pass on different actions to the user.   - Each response will contain an array of objects. Each object represents a different type of notification for a transfer. This notification type can be found on the objects `verb`.     - Types       - transfer_status         - Tells you if a transfer was successful or not. If it was successful there is nothing else you need to do.       - shortlink_generated         - This notification type is created during off network transfers. If the user sends funds to a phone number you will need to get the shortlink from this notifications data. Once you have that you can prompt them to send a text containing the transfer instructions.   - This response will contain data about the transfer such as if it completed successfully, and if there are any more actions you need to take.
     * @summary Returns the notifications for a specific transfer.
     * @param {string} transferId The unique id of the transfer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTransferFeed(
      transferId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Activity>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTransferFeed(transferId, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["NotificationApi.getTransferFeed"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = NotificationApiFp(configuration);
  return {
    /**
     * Gets the notification stream credentials for a user. Once retrieved these credentials can be used with a getstream.io client to fetch notifications for a transfer. Notifications gives you real time data about the transfer. For example - it the transfer succeeded or failed.
     * @summary Get notification stream credentials for user.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getNotificationStream(options?: any): AxiosPromise<Stream> {
      return localVarFp.getNotificationStream(options).then((request) => request(axios, basePath));
    },
    /**
     * Returns the users notification feed. This feed can be used to view app & transfer events such as the `transfer_status` (success/failure). Or, it its an off network transfer you can view the `shortlink_generated`, and more. This endpoint allows you to get the getstream notifcation feed without having to use getstream client locally.
     * @summary Returns the notification feed for the authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStreamFeed(options?: any): AxiosPromise<StreamFeed> {
      return localVarFp.getStreamFeed(options).then((request) => request(axios, basePath));
    },
    /**
     * This endpoint will return notification data for a specific transfer. - **Polling for response**   - Once a transfer is initated using the `POST /transfer/outgoing` endpoint you will need to poll for the response. The goal here is to see if the transfer was successful or if you need to take any additional steps (for example - send the transfer instructions). If you are viewing a transfer in the pending state you can also refetch the instructions if you want the user to send the transfer instructions again.   - If you dont find any notifications in the returned array you will need to wait a second and try again.    - We usually attempt this up to 15 (with a 1 second pause) times before showing an error to the user - telling the user to check back later for the status of the transfer. Its important to note that you should recieve a successful response most of the time.  - **Responses**   - This endpoint will return an array of responses. Depending on the type of response you will need to pass on different actions to the user.   - Each response will contain an array of objects. Each object represents a different type of notification for a transfer. This notification type can be found on the objects `verb`.     - Types       - transfer_status         - Tells you if a transfer was successful or not. If it was successful there is nothing else you need to do.       - shortlink_generated         - This notification type is created during off network transfers. If the user sends funds to a phone number you will need to get the shortlink from this notifications data. Once you have that you can prompt them to send a text containing the transfer instructions.   - This response will contain data about the transfer such as if it completed successfully, and if there are any more actions you need to take.
     * @summary Returns the notifications for a specific transfer.
     * @param {string} transferId The unique id of the transfer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransferFeed(transferId: string, options?: any): AxiosPromise<Array<Activity>> {
      return localVarFp.getTransferFeed(transferId, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
  /**
   * Gets the notification stream credentials for a user. Once retrieved these credentials can be used with a getstream.io client to fetch notifications for a transfer. Notifications gives you real time data about the transfer. For example - it the transfer succeeded or failed.
   * @summary Get notification stream credentials for user.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public getNotificationStream(options?: RawAxiosRequestConfig) {
    return NotificationApiFp(this.configuration)
      .getNotificationStream(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns the users notification feed. This feed can be used to view app & transfer events such as the `transfer_status` (success/failure). Or, it its an off network transfer you can view the `shortlink_generated`, and more. This endpoint allows you to get the getstream notifcation feed without having to use getstream client locally.
   * @summary Returns the notification feed for the authenticated user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public getStreamFeed(options?: RawAxiosRequestConfig) {
    return NotificationApiFp(this.configuration)
      .getStreamFeed(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint will return notification data for a specific transfer. - **Polling for response**   - Once a transfer is initated using the `POST /transfer/outgoing` endpoint you will need to poll for the response. The goal here is to see if the transfer was successful or if you need to take any additional steps (for example - send the transfer instructions). If you are viewing a transfer in the pending state you can also refetch the instructions if you want the user to send the transfer instructions again.   - If you dont find any notifications in the returned array you will need to wait a second and try again.    - We usually attempt this up to 15 (with a 1 second pause) times before showing an error to the user - telling the user to check back later for the status of the transfer. Its important to note that you should recieve a successful response most of the time.  - **Responses**   - This endpoint will return an array of responses. Depending on the type of response you will need to pass on different actions to the user.   - Each response will contain an array of objects. Each object represents a different type of notification for a transfer. This notification type can be found on the objects `verb`.     - Types       - transfer_status         - Tells you if a transfer was successful or not. If it was successful there is nothing else you need to do.       - shortlink_generated         - This notification type is created during off network transfers. If the user sends funds to a phone number you will need to get the shortlink from this notifications data. Once you have that you can prompt them to send a text containing the transfer instructions.   - This response will contain data about the transfer such as if it completed successfully, and if there are any more actions you need to take.
   * @summary Returns the notifications for a specific transfer.
   * @param {string} transferId The unique id of the transfer
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public getTransferFeed(transferId: string, options?: RawAxiosRequestConfig) {
    return NotificationApiFp(this.configuration)
      .getTransferFeed(transferId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SessionApi - axios parameter creator
 * @export
 */
export const SessionApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create a session for an sso user. Sets a cookie which can be used for authentication in subsequent API requests. For use in client-to-server calls.
     * @param {SsoSession} ssoSession Request Payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSsoSession: async (ssoSession: SsoSession, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'ssoSession' is not null or undefined
      assertParamExists("createSsoSession", "ssoSession", ssoSession);
      const localVarPath = `/session/sso`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(ssoSession, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Destroy the session.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    destroySession: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/session`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CookieAuth required

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SessionApi - functional programming interface
 * @export
 */
export const SessionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SessionApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create a session for an sso user. Sets a cookie which can be used for authentication in subsequent API requests. For use in client-to-server calls.
     * @param {SsoSession} ssoSession Request Payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSsoSession(
      ssoSession: SsoSession,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createSsoSession(ssoSession, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["SessionApi.createSsoSession"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Destroy the session.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async destroySession(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.destroySession(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["SessionApi.destroySession"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * SessionApi - factory interface
 * @export
 */
export const SessionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SessionApiFp(configuration);
  return {
    /**
     *
     * @summary Create a session for an sso user. Sets a cookie which can be used for authentication in subsequent API requests. For use in client-to-server calls.
     * @param {SsoSession} ssoSession Request Payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSsoSession(ssoSession: SsoSession, options?: any): AxiosPromise<void> {
      return localVarFp.createSsoSession(ssoSession, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Destroy the session.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    destroySession(options?: any): AxiosPromise<void> {
      return localVarFp.destroySession(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * SessionApi - object-oriented interface
 * @export
 * @class SessionApi
 * @extends {BaseAPI}
 */
export class SessionApi extends BaseAPI {
  /**
   *
   * @summary Create a session for an sso user. Sets a cookie which can be used for authentication in subsequent API requests. For use in client-to-server calls.
   * @param {SsoSession} ssoSession Request Payload
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SessionApi
   */
  public createSsoSession(ssoSession: SsoSession, options?: RawAxiosRequestConfig) {
    return SessionApiFp(this.configuration)
      .createSsoSession(ssoSession, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Destroy the session.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SessionApi
   */
  public destroySession(options?: RawAxiosRequestConfig) {
    return SessionApiFp(this.configuration)
      .destroySession(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TransferApi - axios parameter creator
 * @export
 */
export const TransferApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Cancel payment of an outgoing transfer
     * @param {string} transferId The unique id of the transfer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTransfer: async (transferId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'transferId' is not null or undefined
      assertParamExists("deleteTransfer", "transferId", transferId);
      const localVarPath = `/transfer/outgoing/{transfer_id}`.replace(
        `{${"transfer_id"}}`,
        encodeURIComponent(String(transferId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CookieAuth required

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get incoming transfers
     * @summary Get all incoming transfers for user at institution
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getIncomingTransfers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/transfer/incoming`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CookieAuth required

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get outgoing transfers
     * @summary Get all outgoing transfers for user at institution
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getOutgoingTransfers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/transfer/outgoing`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CookieAuth required

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns an array of the most recent transfers for the current user.
     * @summary Get incoming/outgoing transfers for the authenticated user
     * @param {number} [page] Which page of data you want to retrieve.
     * @param {number} [limit] The number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransferHistory: async (
      page?: number,
      limit?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/transfer/history`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CookieAuth required

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Schedule a transfer to given recipient.  Must provide a unique id NP-Request-Id for the transaction.  It is safe to repeat this request (for example on timeout) provided the same NP-Request-Id is used. - **Testing/Development** in staging environment   - When testing against our staging environment there are a couple values which can simulate transfer failures/pauses and more. These special events are specific to the staging environment and are triggered by sending funds with the dollar amount containg a special number.     - Special Numbers - Any transfer containing one of the following numbers anywhere in the dollar amount will trigger a special event.       - 2: Will fail the transfer. Useful for testing failed transfer flow.       - 3: Ignore 50% of the time, otherwise succeed.       - 4: Close connection 50% of the time, otherwise succeed.       - 5: Wait 30 seconds before completing transfer. Useful for testing delayed notification responses.
     * @summary Schedule a transfer to given recipient
     * @param {string} nPRequestId A unique id for the transfer used to enforce idempotency
     * @param {NewTransfer} newTransfer Request Payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTransfer: async (
      nPRequestId: string,
      newTransfer: NewTransfer,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nPRequestId' is not null or undefined
      assertParamExists("postTransfer", "nPRequestId", nPRequestId);
      // verify required parameter 'newTransfer' is not null or undefined
      assertParamExists("postTransfer", "newTransfer", newTransfer);
      const localVarPath = `/transfer/outgoing`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication CookieAuth required

      // authentication BearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (nPRequestId != null) {
        localVarHeaderParameter["NP-Request-Id"] = String(nPRequestId);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(newTransfer, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TransferApi - functional programming interface
 * @export
 */
export const TransferApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TransferApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Cancel payment of an outgoing transfer
     * @param {string} transferId The unique id of the transfer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteTransfer(
      transferId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTransfer(transferId, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TransferApi.deleteTransfer"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get incoming transfers
     * @summary Get all incoming transfers for user at institution
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async getIncomingTransfers(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IncomingTransfer>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIncomingTransfers(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TransferApi.getIncomingTransfers"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get outgoing transfers
     * @summary Get all outgoing transfers for user at institution
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async getOutgoingTransfers(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutgoingTransfer>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOutgoingTransfers(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TransferApi.getOutgoingTransfers"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Returns an array of the most recent transfers for the current user.
     * @summary Get incoming/outgoing transfers for the authenticated user
     * @param {number} [page] Which page of data you want to retrieve.
     * @param {number} [limit] The number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTransferHistory(
      page?: number,
      limit?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferHistory>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTransferHistory(page, limit, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TransferApi.getTransferHistory"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Schedule a transfer to given recipient.  Must provide a unique id NP-Request-Id for the transaction.  It is safe to repeat this request (for example on timeout) provided the same NP-Request-Id is used. - **Testing/Development** in staging environment   - When testing against our staging environment there are a couple values which can simulate transfer failures/pauses and more. These special events are specific to the staging environment and are triggered by sending funds with the dollar amount containg a special number.     - Special Numbers - Any transfer containing one of the following numbers anywhere in the dollar amount will trigger a special event.       - 2: Will fail the transfer. Useful for testing failed transfer flow.       - 3: Ignore 50% of the time, otherwise succeed.       - 4: Close connection 50% of the time, otherwise succeed.       - 5: Wait 30 seconds before completing transfer. Useful for testing delayed notification responses.
     * @summary Schedule a transfer to given recipient
     * @param {string} nPRequestId A unique id for the transfer used to enforce idempotency
     * @param {NewTransfer} newTransfer Request Payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postTransfer(
      nPRequestId: string,
      newTransfer: NewTransfer,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutgoingTransfer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postTransfer(nPRequestId, newTransfer, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TransferApi.postTransfer"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * TransferApi - factory interface
 * @export
 */
export const TransferApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = TransferApiFp(configuration);
  return {
    /**
     *
     * @summary Cancel payment of an outgoing transfer
     * @param {string} transferId The unique id of the transfer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTransfer(transferId: string, options?: any): AxiosPromise<void> {
      return localVarFp.deleteTransfer(transferId, options).then((request) => request(axios, basePath));
    },
    /**
     * Get incoming transfers
     * @summary Get all incoming transfers for user at institution
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getIncomingTransfers(options?: any): AxiosPromise<Array<IncomingTransfer>> {
      return localVarFp.getIncomingTransfers(options).then((request) => request(axios, basePath));
    },
    /**
     * Get outgoing transfers
     * @summary Get all outgoing transfers for user at institution
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getOutgoingTransfers(options?: any): AxiosPromise<Array<OutgoingTransfer>> {
      return localVarFp.getOutgoingTransfers(options).then((request) => request(axios, basePath));
    },
    /**
     * Returns an array of the most recent transfers for the current user.
     * @summary Get incoming/outgoing transfers for the authenticated user
     * @param {number} [page] Which page of data you want to retrieve.
     * @param {number} [limit] The number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransferHistory(page?: number, limit?: number, options?: any): AxiosPromise<TransferHistory> {
      return localVarFp.getTransferHistory(page, limit, options).then((request) => request(axios, basePath));
    },
    /**
     * Schedule a transfer to given recipient.  Must provide a unique id NP-Request-Id for the transaction.  It is safe to repeat this request (for example on timeout) provided the same NP-Request-Id is used. - **Testing/Development** in staging environment   - When testing against our staging environment there are a couple values which can simulate transfer failures/pauses and more. These special events are specific to the staging environment and are triggered by sending funds with the dollar amount containg a special number.     - Special Numbers - Any transfer containing one of the following numbers anywhere in the dollar amount will trigger a special event.       - 2: Will fail the transfer. Useful for testing failed transfer flow.       - 3: Ignore 50% of the time, otherwise succeed.       - 4: Close connection 50% of the time, otherwise succeed.       - 5: Wait 30 seconds before completing transfer. Useful for testing delayed notification responses.
     * @summary Schedule a transfer to given recipient
     * @param {string} nPRequestId A unique id for the transfer used to enforce idempotency
     * @param {NewTransfer} newTransfer Request Payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTransfer(nPRequestId: string, newTransfer: NewTransfer, options?: any): AxiosPromise<OutgoingTransfer> {
      return localVarFp.postTransfer(nPRequestId, newTransfer, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * TransferApi - object-oriented interface
 * @export
 * @class TransferApi
 * @extends {BaseAPI}
 */
export class TransferApi extends BaseAPI {
  /**
   *
   * @summary Cancel payment of an outgoing transfer
   * @param {string} transferId The unique id of the transfer
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferApi
   */
  public deleteTransfer(transferId: string, options?: RawAxiosRequestConfig) {
    return TransferApiFp(this.configuration)
      .deleteTransfer(transferId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get incoming transfers
   * @summary Get all incoming transfers for user at institution
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof TransferApi
   */
  public getIncomingTransfers(options?: RawAxiosRequestConfig) {
    return TransferApiFp(this.configuration)
      .getIncomingTransfers(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get outgoing transfers
   * @summary Get all outgoing transfers for user at institution
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof TransferApi
   */
  public getOutgoingTransfers(options?: RawAxiosRequestConfig) {
    return TransferApiFp(this.configuration)
      .getOutgoingTransfers(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns an array of the most recent transfers for the current user.
   * @summary Get incoming/outgoing transfers for the authenticated user
   * @param {number} [page] Which page of data you want to retrieve.
   * @param {number} [limit] The number of items to return
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferApi
   */
  public getTransferHistory(page?: number, limit?: number, options?: RawAxiosRequestConfig) {
    return TransferApiFp(this.configuration)
      .getTransferHistory(page, limit, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Schedule a transfer to given recipient.  Must provide a unique id NP-Request-Id for the transaction.  It is safe to repeat this request (for example on timeout) provided the same NP-Request-Id is used. - **Testing/Development** in staging environment   - When testing against our staging environment there are a couple values which can simulate transfer failures/pauses and more. These special events are specific to the staging environment and are triggered by sending funds with the dollar amount containg a special number.     - Special Numbers - Any transfer containing one of the following numbers anywhere in the dollar amount will trigger a special event.       - 2: Will fail the transfer. Useful for testing failed transfer flow.       - 3: Ignore 50% of the time, otherwise succeed.       - 4: Close connection 50% of the time, otherwise succeed.       - 5: Wait 30 seconds before completing transfer. Useful for testing delayed notification responses.
   * @summary Schedule a transfer to given recipient
   * @param {string} nPRequestId A unique id for the transfer used to enforce idempotency
   * @param {NewTransfer} newTransfer Request Payload
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferApi
   */
  public postTransfer(nPRequestId: string, newTransfer: NewTransfer, options?: RawAxiosRequestConfig) {
    return TransferApiFp(this.configuration)
      .postTransfer(nPRequestId, newTransfer, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
