import { useContext, useEffect, useState } from "react";
import FiUiCustomizationContext from "src/stores/FiUiCustomizationStore";
import { FiUiCustomization } from "src/services/generatedApi";

const FiUiCustomizationDetails = () => {
  const fiUiCustomizationStore = useContext(FiUiCustomizationContext);
  const [fiUiCustomizationDetails, setFiUiCustomizationDetails] = useState<FiUiCustomization | undefined>(undefined);
  const [uiLoaded, setUiLoaded] = useState(false);

  const load = async () => {
    try {
      await fiUiCustomizationStore.loadFiUiCustomization();
      setFiUiCustomizationDetails(fiUiCustomizationStore._details);
      setUiLoaded(true);
    } catch (error) {
      console.log("ui load error", error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      load();
    }, 200);
    const interval = setInterval(() => {
      if (!uiLoaded) {
        load();
      }
    }, 1000);
    setTimeout(() => {
      clearInterval(interval);
    }, 5000);
    return () => clearInterval(interval);
  }, [uiLoaded]);

  return { fiUiCustomizationDetails };
};

export default FiUiCustomizationDetails;
