import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Button, Grid, Typography } from "@mui/material";
import logo from "src/assets/logo.svg";
import { ROUTE_CONSTANTS } from "src/constants";
import bg from "src/assets/bg_site.jpg";
import AppContext from "src/stores/AppStore";

const body = {
  backgroundImage: `url(${bg})`,
  backgroundSize: "1440px 900px",
  backgroundPosition: "top center",
  height: "100vh",
};

const logoStyle = {
  width: 240,
  maxWidth: "100%",
  display: "block",
  margin: "0 auto 48px",
};

const title = {
  maxWidth: "100%",
  fontSize: "2.4rem",
  marginBottom: "56px",
};

const heroButton = {
  maxWidth: "100%",
  width: 380,
  margin: "16px auto 0",
  display: "block",
  borderRadius: "10px",
  cursor: "pointer",
};

export const SignOut = observer(() => {
  const navigate = useNavigate();
  const appStore = useContext(AppContext);

  async function handleClick(path: string) {
    try {
      navigate(path);
    } catch (err) {
      navigate(path);
    }
  }

  appStore.logOut();

  return (
    <React.Fragment>
      <main style={body}>
        <div style={{ padding: "80px 32px 48px" }}>
          <img alt="Neural" src={logo} style={logoStyle} />
          <Grid style={{ padding: "80px 40px 40px" }}>
            <Typography component="h1" variant="h2" align="center" style={title}>
              You've been logged out
            </Typography>
            <Button
              href=""
              style={heroButton}
              variant="contained"
              data-testid="signout_button"
              onClick={() => handleClick(ROUTE_CONSTANTS.DEMO_LOGIN_PATH)}
            >
              Return to app
            </Button>
          </Grid>
        </div>
      </main>
    </React.Fragment>
  );
});

export default SignOut;
