export const ROUTE_CONSTANTS = {
  MAIN_PAGE_PATH: "/app",
  TOKEN_SIGN_IN_PATH: "/app/signin/token",
  DEMO_LOGIN_PATH: "/app/signin",
  SIGN_OUT_PATH: "/app/sign-out",
  HISTORY_PAGE_PATH: "/app/history",
  OUTGOING_DETAILS_PATH: "/app/outgoing-details/:id",
  INCOMING_DETAILS_PATH: "/app/incoming-details/:id",
  SETTINGS_PATH: "/app/settings",
  SET_DEFAULT_ACCOUNT_PATH: "/app/settings/default-account/:id",
  MONEY_SENT_PATH: "/app/transfer-successful/:id",
  MONEY_NOT_SENT_PATH: "/app/transfer-unsuccessful/:id",
  SMS_PENDING: "/app/sms-pending/:id",
  BANNO_PLUGIN: "/app/:method/:idp/banno-plugin",
  NP_EVENT_DEMO: "/event_demo",
  FUNDING_PATH: "/funding",
  FUNDING_SIGN_IN_PATH: "/funding/signin/token",
};
