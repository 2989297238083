import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import CheckIcon from "@mui/icons-material/Check";
import { Grid, Typography, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Radio } from "@mui/material";
import AccountContext from "src/stores/AccountStore";
import IdentifierContext from "src/stores/IdentifierStore";
import CardContext from "src/stores/CardStore";
import ButtonBack from "src/components/App/ButtonBack";
import CardDisplay from "../CardDisplay";
import { Account, Card } from "src/services/generatedApi";
import { ROUTE_CONSTANTS } from "src/constants";
import GlobalTheme from "src/theme";

const { DEMO_LOGIN_PATH, SETTINGS_PATH } = ROUTE_CONSTANTS;

interface IURLParams {
  id?: string;
}

const SetDefaultAccount = observer(({ appStyles }: { appStyles: any }) => {
  const identifierStore = useContext(IdentifierContext);
  const accountStore = useContext(AccountContext);
  const cardStore = useContext(CardContext);
  const navigate = useNavigate();

  const urlParams: IURLParams = useParams();
  const [account, setAccount] = React.useState<Account | undefined>(undefined);
  const [card, setCard] = React.useState<Card | undefined>(undefined);
  const [filteredCards, setFilteredCards] = React.useState<Card[] | undefined>(undefined);
  const { fi_ui_customization } = GlobalTheme();

  const filterCards = (account: Account) => {
    const filtered = cardStore.getFilteredCards(account);
    setFilteredCards(filtered);
    return filtered;
  };

  const shouldShowCards = () => filteredCards && filteredCards.length > 1;

  const updateSelectedDefaults = async (newAccount: Account, newCard: Card) => {
    setAccount(newAccount);
    const useCards = filterCards(newAccount);

    // if the old selected card is not part of the list of cards
    // available to the new selected account, force a card change
    // TODO 🫁: handle for if there are no cards at all
    const useCard =
      useCards.filter((card) => newAccount.card_uuids.includes(card.uuid)).length === 0 ? useCards[0] : newCard;
    setCard(useCard);

    const type = identifierStore.identifiers[urlParams.id as any].type;
    const value = identifierStore.identifiers[urlParams.id as any].value;
    const card_uuid = useCard?.uuid;

    await identifierStore.setDefaultAccount(type, value, newAccount.id, card_uuid);
  };

  const handleSelectAccount = async (newAccount: Account) => {
    if (filteredCards) {
      const useCard = card || filteredCards[0];
      await updateSelectedDefaults(newAccount, useCard);
    }
  };

  const handleSelectedCard = async (newCard: Card) => {
    if (account) {
      await updateSelectedDefaults(account, newCard);
    }
  };

  useEffect(() => {
    const loadStores = async () => {
      try {
        await identifierStore.loadIdentifiers();
        await identifierStore.loadDefaultAccounts();
        await accountStore.loadAccounts();
        await cardStore.loadCards();

        filterCards(accountStore.accounts[0]);

        const id = urlParams.id as any;
        if (identifierStore.identifiers.length > id) {
          const identifier = identifierStore.identifiers[id];
          const type = identifier.type;
          const value = identifier.value;

          // TODO 🫁: rethink getDefaultAccount with refactored settings API
          const defaultAccountId = identifierStore.getDefaultAccount(type, value);
          if (defaultAccountId) {
            const foundAccount = accountStore.accounts.find(({ id }) => id === defaultAccountId);
            if (foundAccount) {
              setAccount(foundAccount);

              if (identifier.default_card) {
                const rFilteredCards = filterCards(accountStore.accounts[0]);
                if (rFilteredCards) {
                  const foundCard = rFilteredCards.find((card) => card.uuid === identifier.default_card);
                  if (foundCard) {
                    setCard(foundCard);
                  }
                }
              }
            }
          }
        }
      } catch (error) {
        navigate(DEMO_LOGIN_PATH);
      }
    };
    loadStores();
  }, [identifierStore, identifierStore, accountStore, cardStore, navigate]);

  const { renderBackButton } = ButtonBack(SETTINGS_PATH);

  return (
    <React.Fragment>
      <main>
        <Grid container>
          <Grid
            style={{ ...appStyles.row2, ...appStyles.bgDark, paddingBottom: "0", position: "relative" }}
            justifyContent="center"
            container
          >
            {renderBackButton()}
            <Typography component="div" style={appStyles.title}>
              <span>Set default account</span> <br />
              <Typography style={{ position: "relative", top: "-6px" }} component="span" color="secondary.light">
                for {identifierStore.identifiers.length && identifierStore.identifiers[urlParams.id as any].value}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        <div>
          {shouldShowCards() && (
            <div style={{ ...appStyles.row3, ...appStyles.bgMedium }}>
              <Typography component="p" variant="subtitle2" color="secondary.light">
                Default Account
              </Typography>
            </div>
          )}
          <List style={appStyles.list2}>
            {accountStore.accounts.map((listAccount, index1) => {
              return (
                <React.Fragment key={index1}>
                  <ListItem
                    style={{ ...appStyles.row2, position: "relative" }}
                    button
                    onClick={() => handleSelectAccount(listAccount)}
                  >
                    <ListItemText
                      primary={
                        <Typography component="p" variant="subtitle1" color="secondary.dark">
                          {listAccount.name}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Radio
                        checked={account === listAccount}
                        value={listAccount.name}
                        color="default"
                        name="default-account"
                        inputProps={{ "aria-label": listAccount.name }}
                        icon={<React.Fragment />}
                        checkedIcon={<CheckIcon style={{ color: fi_ui_customization.text_dark }} />}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              );
            })}
          </List>
        </div>

        {filteredCards && shouldShowCards() && (
          <div>
            <div style={{ ...appStyles.row3, ...appStyles.bgMedium }}>
              <Typography component="p" variant="subtitle2" color="secondary.light">
                Default Card
              </Typography>
            </div>
            <List style={appStyles.list2}>
              {filteredCards.map((listCard) => {
                return (
                  <React.Fragment key={listCard.uuid}>
                    <ListItem
                      style={{ ...appStyles.row2, position: "relative" }}
                      button
                      onClick={() => handleSelectedCard(listCard)}
                    >
                      <ListItemText
                        primary={
                          <Typography component="p" variant="subtitle1" color="secondary.dark">
                            <CardDisplay card={listCard} />
                          </Typography>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Radio
                          checked={listCard.uuid === card?.uuid}
                          value={listCard.uuid}
                          color="default"
                          name="default-account"
                          inputProps={{ "aria-label": listCard.uuid }}
                          icon={<React.Fragment />}
                          checkedIcon={<CheckIcon style={{ color: fi_ui_customization.text_dark }} />}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                );
              })}
            </List>
          </div>
        )}
      </main>
    </React.Fragment>
  );
});

export default SetDefaultAccount;
