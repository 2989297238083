import React from "react";
import { Grid } from "@mui/material";
import bg from "src/assets/bg_site.jpg";

const body = {
  backgroundImage: `url(${bg})`,
  backgroundSize: "1440px 900px",
  backgroundPosition: "top center",
  height: "100vh",
};

export const Login = () => {
  return (
    <main style={body}>
      <div style={{ padding: "80px 32px 48px", textAlign: "center" }}>
        <Grid container justifyContent="center">
          <h3>You are not registered to use this service.</h3>
        </Grid>
        <Grid container justifyContent="center">
          <h4>Please contact support for assistance</h4>
        </Grid>
      </div>
    </main>
  );
};

export default Login;
