import React from "react";
import { formatRoute } from "react-router-named-routes";
import { Typography, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { TransferHistoryItem as TransferHistoryItemType } from "src/services/generatedApi";
import { ROUTE_CONSTANTS } from "src/constants";
import ico_receive from "src/assets/ico_receive.svg";

const { INCOMING_DETAILS_PATH } = ROUTE_CONSTANTS;

const IncomingTransfer = ({
  incoming,
  handleLinkClick,
  appStyles,
}: {
  incoming: TransferHistoryItemType;
  handleLinkClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, path: string) => void;
  appStyles: any;
}) => {
  return (
    <ListItem
      button
      onClick={(event) => handleLinkClick(event, formatRoute(INCOMING_DETAILS_PATH, { id: incoming.id }))}
    >
      <ListItemAvatar>
        <div style={{ ...appStyles.circle, ...appStyles.incomingIcoBg, textAlign: "center" }}>
          <img src={ico_receive} alt="Incoming transfer icon" style={appStyles.icoTransfer} />
        </div>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography component="p" variant="body2" color="secondary.light">
            Incoming transfer
          </Typography>
        }
        secondary={
          <Typography component="p" variant="body1">
            {incoming.sender_name}
          </Typography>
        }
      />
      <ListItemText
        primary={
          <React.Fragment>
            <Typography style={{ ...appStyles.amount, ...appStyles.green, textAlign: "right" }}>
              +${incoming.amount.value}
            </Typography>
          </React.Fragment>
        }
      />
    </ListItem>
  );
};

export default IncomingTransfer;
