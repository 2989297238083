import React from "react";
import { Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

const heroContent = {
  maxWidth: "px",
  margin: "0 auto",
  padding: "64px 0 48px",
};

const root = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
};

const NotFound = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <main style={root}>
        <div>
          <div style={heroContent}>
            <Typography variant="h5">Page Not Found</Typography>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default NotFound;
