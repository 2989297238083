import React from "react";

import { Typography, TextField, InputAdornment } from "@mui/material";

import ico_close from "src/assets/close.svg";

export const Recipient = ({
  handleRecipientChange,
  setRecipient,
  recipientError,
  recipient,
  appStyles,
}: {
  handleRecipientChange: () => void;
  setRecipient: (arg0: string) => void;
  recipientError: boolean | undefined;
  recipient: string;
  appStyles: any;
}) => {
  return (
    <>
      <div
        style={{
          ...appStyles.bgMedium,
          padding: "0 8px",
          // marginTop: "20px"
        }}
      >
        <TextField
          variant="standard"
          value={recipient}
          fullWidth
          error={recipientError}
          label={"Email"}
          InputProps={{
            style: { ...appStyles.inputRoot, marginTop: "30px" },
            endAdornment: (
              <InputAdornment position="end">
                {recipient && <img onClick={() => setRecipient("")} src={ico_close} alt="Reset Recipient Icon" />}
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            style: { ...appStyles.labelRoot },
          }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange={handleRecipientChange()}
        />
      </div>
      {recipientError && recipient && (
        <div style={{ ...appStyles.errorInput, position: "relative" }}>
          <span style={{ ...appStyles.triangleT, position: "absolute" }}>&nbsp;</span>
          <Typography>Please enter a valid email</Typography>
        </div>
      )}
    </>
  );
};
