import { action, computed, observable } from "mobx";
import { createContext } from "react";
import { Identifier } from "src/services/generatedApi";
import { accountApi, identifierApi } from "src/services/api";

export class IdentifierStore {
  // tslint:disable-next-line: variable-name
  @observable private _identifiers: Identifier[] = [];
  // tslint:disable-next-line: variable-name
  @observable private _defaults: Map<string, Map<string, string>> = new Map();

  @computed public get identifiers() {
    return this._identifiers;
  }

  public set identifiers(identifiers: Identifier[]) {
    this._identifiers = identifiers;
  }

  @computed public get defaults() {
    return this._defaults;
  }

  public getDefaultAccount(type: string, value: string) {
    const defaultsByType = this.defaults.get(type);
    if (defaultsByType) {
      return defaultsByType.get(value);
    }
    return undefined;
  }

  @action public async loadIdentifiers() {
    const response = await identifierApi.getUserIdentifiers();
    this._identifiers = response.data;
  }

  public setInternal(type: string, value: string, accountId: string) {
    if (this._defaults) {
      if (!this._defaults.has(type)) {
        this._defaults.set(type, new Map());
      }
      const defaultsByType = this._defaults.get(type);
      // TODO: investigate a better option than disabling this
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      defaultsByType!.set(value, accountId);
    }
  }

  @action public async loadDefaultAccounts() {
    this._defaults.clear();
    const accounts = (await accountApi.getAccounts()).data;

    // collect promises then wait on them later, so requests
    // are done in parallel
    const promises = accounts.map((account) => {
      const p = accountApi.getDefaultNetworkAccount(account.id);
      return p;
    });

    promises.forEach(async (p, idx) => {
      const accountId = accounts[idx].id;
      const accountDefaults = (await p).data;
      accountDefaults.forEach((identifier) => {
        this.setInternal(identifier.type, identifier.value, accountId);
      });
    });
  }

  @action public async setDefaultAccount(type: string, value: string, accountId: string, defaultCard?: string) {
    await accountApi.setDefaultNetworkAccount(accountId, type, value, defaultCard);
    this.setInternal(type, value, accountId);
  }

  @action public clear() {
    this._identifiers = [];
    this._defaults.clear();
  }
}

const identifierStore = new IdentifierStore();
export default createContext(identifierStore);
