import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { v4 } from "uuid";
import { Typography, Button, TextField } from "@mui/material";
import axios from "axios";
import { EventDemoBar } from "src/components";
import { Account, Card } from "src/services/generatedApi";
import AccountContext from "src/stores/AccountStore";
import CardContext from "src/stores/CardStore";
import NotificationContext from "src/stores/NotificationStore";
import TransferContext from "src/stores/TransferStore";
import { Recipient } from "./Recipient";
import { ROUTE_CONSTANTS } from "src/constants";

const { DEMO_LOGIN_PATH, SMS_PENDING } = ROUTE_CONSTANTS;

const NPEventDemo = observer(({ appStyles, setFromDemoPage }: { appStyles: any; setFromDemoPage: any }) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const accountStore = useContext(AccountContext);
  const cardStore = useContext(CardContext);
  const transferStore = useContext(TransferContext);
  const notificationStore = useContext(NotificationContext);
  const navigate = useNavigate();
  const requestId = v4();
  const [isLoading, setIsLoading] = React.useState(false);
  const [account, setAccount] = React.useState<Account | undefined>(undefined);
  const card = React.useState<Card | undefined>(undefined);
  const [recipient, setRecipient] = React.useState(params.recipient || "");
  const [recipientError, setRecipientError] = React.useState(false);
  const [error, setError] = React.useState<undefined | string>(undefined);
  const [isValidInput, setIsValidInput] = useState(false);

  useEffect(() => {
    setFromDemoPage(true);
  }, []);

  const validateRecipient = () => {
    setRecipientError(false);
    const recipientType = "email";

    const recipientValue = recipient.trim().toLowerCase();
    const e =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!e.test(recipientValue)) {
      setRecipientError(true);
      return [];
    }
    return [{ type: recipientType, value: recipientValue }];
  };

  const handleRecipientChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    setRecipientError(false);
    setRecipient(event.target.value ? event.target.value : "");
    if (
      (event.target.value.match(/^[0-9, +, \-, (, )]+$/) != null && event.target.value.length > 9) ||
      event.target.value
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
    ) {
      setIsValidInput(true);
    } else {
      setIsValidInput(false);
    }
  };

  const flashError = (e: string) => {
    setError(e);
    setTimeout(() => {
      setError(undefined);
    }, 3000);
  };

  interface UserData {
    name: string;
    title: string;
    company: string;
  }

  const [userData, setUserData] = useState<UserData>({
    name: "",
    title: "",
    company: "",
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    setError(undefined);
    event.preventDefault();
    const note = `Neural Payments Demo - name: ${userData.name}, title: ${userData.title}, company: ${userData.company}`;
    if (account) {
      try {
        const wrappedRecipient = validateRecipient();

        if (wrappedRecipient.length < 1) {
          flashError("Invalid email");
          return;
        }

        const response = await transferStore.postTransfer(
          requestId,
          account.id,
          card?.uuid,
          "5",
          wrappedRecipient,
          note,
        );

        const transferId = response.id;
        notificationStore.isLoading = true;
        notificationStore.inProgressTransferId = transferId;
        navigate(formatRoute(SMS_PENDING, { id: response.id }));
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          // Access to config, request, and response
          console.log(err.response);
          flashError(err.response?.data?.detail);
        } else {
          console.log("An unknown error occurred");
          flashError("An error ocurred while sending these funds.");
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const loadStores = async () => {
      try {
        await accountStore.loadAccounts();
        await cardStore.loadCards();
        await notificationStore.loadNotifications();
      } catch (_err) {
        navigate(DEMO_LOGIN_PATH);
      } finally {
        setAccount(accountStore.accounts[0]);
        notificationStore.resetPhoneTransactions();
      }
    };
    loadStores();
  }, [notificationStore, cardStore, accountStore, navigate]);

  let submitBtnTxt = "Enter user info above";
  if (recipientError) {
    submitBtnTxt = "Enter valid email";
  } else if (recipient && userData.name && userData.title && userData.company && isValidInput) {
    submitBtnTxt = "Send Payment Now";
  }

  return (
    <React.Fragment>
      <main>
        <EventDemoBar appStyles={appStyles} />
        <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
          <div>
            <Recipient
              handleRecipientChange={handleRecipientChange}
              setRecipient={setRecipient}
              recipientError={recipientError}
              recipient={recipient}
              appStyles={appStyles}
            />
            <div style={{ height: "20px" }}></div>
            <div style={{ ...appStyles.bgMedium, padding: "0 8px" }}>
              <TextField
                variant="standard"
                fullWidth
                label="Name"
                name="name"
                onChange={handleInputChange}
                placeholder=""
                multiline
                InputProps={{ style: { ...appStyles.inputRoot, marginTop: "30px" } }}
                InputLabelProps={{
                  style: { ...appStyles.labelRoot },
                }}
              />
            </div>
            <div style={{ height: "20px" }}></div>
            <div style={{ ...appStyles.bgMedium, padding: "0 8px" }}>
              <TextField
                variant="standard"
                fullWidth
                label="Title"
                name="title"
                onChange={handleInputChange}
                placeholder=""
                multiline
                InputProps={{ style: { ...appStyles.inputRoot, marginTop: "30px" } }}
                InputLabelProps={{
                  style: { ...appStyles.labelRoot },
                }}
              />
            </div>
            <div style={{ height: "20px" }}></div>
            <div style={{ ...appStyles.bgMedium, padding: "0 8px" }}>
              <TextField
                variant="standard"
                fullWidth
                label="Company"
                name="company"
                onChange={handleInputChange}
                placeholder=""
                multiline
                InputProps={{ style: { ...appStyles.inputRoot, marginTop: "30px" } }}
                InputLabelProps={{
                  style: { ...appStyles.labelRoot },
                }}
              />
            </div>
          </div>
          <div style={{ height: "20px" }}></div>
          <div style={{ textAlign: "center" }}>
            {error && (
              <>
                <div style={{ ...appStyles.row, ...appStyles.bgDark, textAlign: "center" }}>
                  <Typography component="p" variant="subtitle1">
                    {error}
                  </Typography>
                </div>
              </>
            )}
          </div>
          <div style={{ ...appStyles.buttonHolder, marginTop: "30px" }}>
            <Button
              disabled={
                !recipient ||
                recipientError ||
                isLoading ||
                !isValidInput ||
                !userData.name ||
                !userData.title ||
                !userData.company
              }
              type="submit"
              fullWidth
            >
              {submitBtnTxt}
            </Button>
          </div>
        </form>
      </main>
    </React.Fragment>
  );
});

export default NPEventDemo;
