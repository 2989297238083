import { postUniversalMessage } from "@jack-henry/banno-plugin-framework-bridge";
/**
 * Uses the banno plugin bridge click-link app event to open link in an external browser.
 * This is useful when you need to send a sms message. If the postUniversalMessage is unsuccessful
 * It will fallback to setting the sms as a href. This is useful if you in a banno flow
 * but in a new tab and cant send messages to a parent frame.
 * @param {string} href - The link you want to send the user to
 * @param {boolean} external - If you want to open this in a new tab/out of the mobile app
 * @returns Opens link in banno environment
 */
export const hrefRouter = (href: string, external: boolean) => {
  const messageSent = postUniversalMessage({ type: "click-link", data: { href: href, external: external } });
  // Message was not sent. Fallback to setting window href
  if (!messageSent) {
    window.location.href = href;
  }
};
