const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const stddatetime = (date: Date) => {
  const month = months[date.getMonth()];
  const day = date.getDate();
  let hours = date.getHours();
  let am_pm = "AM";
  const minutes = "0" + date.getMinutes();
  if (hours === 0) {
    hours = 12;
  } else if (hours === 12) {
    am_pm = "PM";
  } else if (hours > 12) {
    am_pm = "PM";
    hours -= 12;
  }
  return month + " " + day + " " + hours + ":" + minutes.slice(-2) + " " + am_pm;
};

export const stddate = (date: Date) => {
  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();
  return year + " " + month + " " + day;
};

export const deviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
  ) {
    return "mobile";
  }
  return "desktop";
};
