import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Route, Routes } from "react-router-dom";
import { ROUTE_CONSTANTS } from "src/constants";
import {
  NotFound,
  HistoryList,
  Login,
  SendMoney,
  SMSProcessing,
  BannoPlugin,
  NPEventDemo,
  FundingiFrame,
} from "src/screens";
import {
  IncomingDetails,
  OutgoingDetails,
  SetDefaultAccount,
  Settings,
  SignOut,
  TransferSuccessful,
  TransferFailed,
  TokenSignIn,
} from "src/components";
import GlobalStyles from "src/theme/app";
import GlobalTheme from "src/theme";

const {
  TOKEN_SIGN_IN_PATH,
  DEMO_LOGIN_PATH,
  SIGN_OUT_PATH,
  HISTORY_PAGE_PATH,
  OUTGOING_DETAILS_PATH,
  INCOMING_DETAILS_PATH,
  MAIN_PAGE_PATH,
  SETTINGS_PATH,
  SET_DEFAULT_ACCOUNT_PATH,
  MONEY_SENT_PATH,
  MONEY_NOT_SENT_PATH,
  SMS_PENDING,
  BANNO_PLUGIN,
  NP_EVENT_DEMO,
  FUNDING_PATH,
  FUNDING_SIGN_IN_PATH,
} = ROUTE_CONSTANTS;

const AppRouter = () => {
  const { appStyles } = GlobalStyles();
  const { fi_ui_customization } = GlobalTheme();
  const [fundingToken, setFundingToken] = useState("");
  const [fromDemoPage, setFromDemoPage] = useState(false);

  return (
    <div style={{ height: "100vh" }} className="wrapper">
      <CssBaseline />
      <Routes>
        <Route
          path={MAIN_PAGE_PATH}
          element={
            <SendMoney
              appStyles={appStyles}
              fi_ui_customization={fi_ui_customization}
              setFromDemoPage={setFromDemoPage}
            />
          }
        />
        {[TOKEN_SIGN_IN_PATH, FUNDING_SIGN_IN_PATH].map((path) => (
          <Route path={path} key={path} element={<TokenSignIn setFundingToken={setFundingToken} />} />
        ))}
        <Route path={DEMO_LOGIN_PATH} element={<Login />} />
        <Route path={SIGN_OUT_PATH} element={<SignOut />} />
        <Route path={HISTORY_PAGE_PATH} element={<HistoryList appStyles={appStyles} fromDemoPage={fromDemoPage} />} />
        <Route path={OUTGOING_DETAILS_PATH} element={<OutgoingDetails appStyles={appStyles} />} />
        <Route path={INCOMING_DETAILS_PATH} element={<IncomingDetails appStyles={appStyles} />} />
        <Route
          path={MONEY_SENT_PATH}
          element={<TransferSuccessful appStyles={appStyles} fromDemoPage={fromDemoPage} />}
        />
        <Route
          path={MONEY_NOT_SENT_PATH}
          element={<TransferFailed appStyles={appStyles} fromDemoPage={fromDemoPage} />}
        />
        <Route path={SETTINGS_PATH} element={<Settings appStyles={appStyles} />} />
        <Route path={SET_DEFAULT_ACCOUNT_PATH} element={<SetDefaultAccount appStyles={appStyles} />} />
        <Route path={SMS_PENDING} element={<SMSProcessing appStyles={appStyles} fromDemoPage={fromDemoPage} />} />
        <Route path={BANNO_PLUGIN} element={<BannoPlugin />} />
        {fi_ui_customization.event_demo && (
          <Route
            path={NP_EVENT_DEMO}
            element={<NPEventDemo appStyles={appStyles} setFromDemoPage={setFromDemoPage} />}
          />
        )}
        <Route path={FUNDING_PATH} element={<FundingiFrame fundingToken={fundingToken} />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default AppRouter;
