import React from "react";
import GlobalTheme from "src/theme";

export const FundingiFrame = ({ fundingToken }: { fundingToken: string }) => {
  const { fi_ui_customization } = GlobalTheme();

  const getIframeUrl = () => {
    const domain = window.location.host;
    if (domain === "localhost:5101") {
      return "http://localhost:3000";
    }
    if (domain === "embedded.staging.neuralpayments.com") {
      return "https://p2c.app.staging.neuralpayments.com";
    }
    if (domain === "embedded.neuralpayments.com") {
      return "https://p2c.app.neuralpayments.com";
    }
    return "https://p2c.app.neuralpayments.com";
  };

  const iframeUrl = `${getIframeUrl()}?off_network_bg_primary=${fi_ui_customization.off_network_bg_primary.slice(
    1,
    7,
  )}&off_network_bg_secondary=${fi_ui_customization.off_network_bg_secondary.slice(
    1,
    7,
  )}&off_network_button_color=${fi_ui_customization.off_network_button_color.slice(
    1,
    7,
  )}&off_network_text_color=${fi_ui_customization.off_network_text_color.slice(
    1,
    7,
  )}&app=funding&token=${fundingToken}`;

  return (
    <iframe
      title={"debit-card-input-iframe"}
      id="cde-iframe"
      style={{ border: "0", height: "100vh" }}
      width={"100%"}
      scrolling={"no"}
      src={iframeUrl}
      allow=""
    ></iframe>
  );
};

export default FundingiFrame;
