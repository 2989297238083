import { action, computed, observable } from "mobx";
import { createContext } from "react";
import { Account, Card } from "src/services/generatedApi";
import { cardApi } from "src/services/api";

export class CardStore {
  // tslint:disable-next-line: variable-name
  @observable private _cards: Card[] = [];

  @computed public get cards() {
    return this._cards;
  }

  public set cards(cards: Card[]) {
    this._cards = cards;
  }

  @action public async loadCards() {
    const response = await cardApi.getCards();
    this._cards = response.data;
  }

  @action public clear() {
    this._cards = [];
  }

  @action public getFilteredCards(account: Account) {
    return this._cards.filter((card) => account.card_uuids.includes(card.uuid));
  }
}

const cardStore = new CardStore();
export default createContext(cardStore);
