import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { CircularProgress, Box } from "@mui/material";

import AppContext from "src/stores/AppStore";

import { ROUTE_CONSTANTS } from "src/constants";
import { MOBILE_PROVIDERS } from "src/constants";
import axios from "axios";

export const TokenSignIn = observer(
  ({ setFundingToken }: { setFundingToken: React.Dispatch<React.SetStateAction<string>> }) => {
    const navigate = useNavigate();
    const appStore = useContext(AppContext);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    // Set default state
    const [errorMessage, setErrorMessage] = useState("An unknown error occured. Please try again later.");
    const [showError, setShowError] = useState(false);
    const [showLoader, setShowLoader] = useState(true);

    const displayError = async (msg?: string) => {
      setShowLoader(false);
      setShowError(true);

      if (msg) {
        setErrorMessage(msg);
      }
    };

    const handleLogin = async (token: string) => {
      try {
        await appStore.ssoLogIn(token);
        // Logged in - Send user to next page

        // Get query params ready. Remove any old values and pass what we need for next page
        // Remove token from params
        delete params.token;

        // If 'next' query param is present - send the user there
        let nextPath = ROUTE_CONSTANTS.MAIN_PAGE_PATH;
        if (window.location.pathname.includes("funding")) {
          nextPath = ROUTE_CONSTANTS.FUNDING_PATH;
          setFundingToken(token);
        }
        if (params.next) {
          nextPath = params.next;
          delete params.next;
        }

        // If 'mobileProvider' query param is present - set in local storage
        // if not set then set as NONE
        if (params.mobileProvider && params.mobileProvider in MOBILE_PROVIDERS) {
          // if params
          localStorage.setItem("mobileProvider", params.mobileProvider);
          delete params.mobileProvider;
        } else {
          localStorage.setItem("mobileProvider", "NONE");
        }

        // Send to next page with any left over querys params
        // A good example of left over params is amount and recipient when going to send page
        navigate({
          pathname: nextPath,
          search: Object.keys(params)
            .map((key) => key + "=" + encodeURIComponent(params[key]))
            .join("&"),
        });
      } catch (err) {
        console.log("Err", err);
        if (axios.isAxiosError(err) && err.response) {
          // Access to config, request, and response

          console.log(err.response);
          // Error returned from embedded api -
          // this is likely because the user_id for this user is not valid.
          displayError("Looks like your account is not set up. Please check back again later.");
          return;
        }
        displayError();
      }
    };

    // SSO login process
    useEffect(() => {
      const token = params["token"];

      if (!token) {
        console.error("No token found");
        displayError();
        return;
      }

      // Make request to embedded api to set cookie
      handleLogin(token);
    }, [appStore, navigate]);

    const Error = () => {
      return (
        <div>
          <h1 id="error">Oh no...</h1>
          <h4 id="error">{errorMessage}</h4>
        </div>
      );
    };

    return (
      <main>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          {showError ? <Error /> : null}
          {showLoader ? <CircularProgress color="inherit" size={"100px"} /> : null}
        </Box>
      </main>
    );
  },
);

export default TokenSignIn;
