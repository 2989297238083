import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";

import { Grid, Typography, Fab } from "@mui/material";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import { deviceType } from "../../utils/index";
import { ROUTE_CONSTANTS } from "src/constants";
import { MOBILE_PROVIDERS } from "src/constants";
import { enableRouter, ping } from "@jack-henry/banno-plugin-framework-bridge";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";

// Parse color from query param if applicable
// Example params: ?bg=434343&text=ffffff&button=light
const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

const bgColor = params.bg || "20201E"; // Hex value
const textcolor = params.text || "ffffff"; // Hex value
const buttonColor = params.buttonColor || "1B9885";

const frame = {
  backgroundColor: "#" + bgColor,
  color: "#" + textcolor,
  height: "100%",
  width: "100%",
};

const theme = createTheme({
  components: {
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px",
          textTransform: "none",
          fontSize: "15px",
          fontWeight: "bold",
          backgroundColor: "#" + bgColor,
          border: "1px solid #" + buttonColor + "30", // 30 adds opacity
          color: "#" + buttonColor,
          "&:hover": {
            backgroundColor: "#" + buttonColor + "25", // 25 adds opacity
          },
        },
      },
    },
  },
});

interface IURLParams {
  idp?: string;
  method?: string;
}

export const BannoPlugin = observer(() => {
  const { HISTORY_PAGE_PATH, SETTINGS_PATH, MAIN_PAGE_PATH } = ROUTE_CONSTANTS;

  const urlParams: IURLParams = useParams();
  const [recipient, setRecipient] = useState("");
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const [shrinkButtons, setShrinkButtons] = useState(false);

  const method = urlParams.method;
  const idp = urlParams.idp;

  useEffect(() => {
    // Enable Banno Plugin Bridge router
    // https://github.com/Banno/banno-plugin-framework-bridge
    enableRouter();
    // prevent host app from timing out
    ping();
  }, []);

  // Get width and show/hide button text accordingly
  useEffect(() => {
    const box = document.getElementById("header");
    if (box != null) {
      const width = box.clientWidth;
      if (width <= 400) {
        setShrinkButtons(true);
      }
    }
  }, []);

  const clickHandler = (event: React.MouseEvent<Element, MouseEvent>, redirectTo: string) => {
    event.preventDefault();
    const authSpInitUrl = `${process.env.REACT_APP_AUTH_SERVER_ORIGIN}/login/sso/${method}/${idp}`;
    let queryString = `?next=${encodeURIComponent(redirectTo)}&mobileProvider=${MOBILE_PROVIDERS.BANNO}`;

    console.log("Clicked!");
    // const button: HTMLButtonElement = event.currentTarget;
    // setClickedButton(button.name);

    if (redirectTo == MAIN_PAGE_PATH) {
      // Run form validation
      setError(""); //Reset error
      if (recipient == "") {
        setError("Recipient cannot be empty");
        return;
      }
      if (amount == "") {
        setError("Amount cannot be empty");
        return;
      }
      // add recipient and amount
      queryString += `&recipient=${encodeURIComponent(recipient)}&amount=${encodeURIComponent(amount)}`;
    }
    console.log("REDIRECT URI", authSpInitUrl + queryString);
    // Get sendhidden link and trigger a click
    const sendhidden = document.getElementById("sendhidden");

    // Click <a> tag and start auth flow
    if (sendhidden !== null) {
      // If mobile device open in app in internal web view
      // If not mobile then open in new tab
      // For how targets work look at the Banno plugin bridge docs
      if (deviceType() === "mobile") {
        sendhidden.setAttribute("target", "_top");
      } else {
        sendhidden.setAttribute("target", "_blank");
      }

      sendhidden.setAttribute("href", authSpInitUrl + queryString);
      sendhidden.click();
      // Reset form to blank values
      // this matters on the banno mobile view when extension is opened as popup
      setRecipient("");
      setAmount("");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <main style={frame}>
        <div>
          {/* Load styles used for banno page */}
          <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
          <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
            integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
            crossOrigin="anonymous"
          ></link>
          <script
            src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
            integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
            crossOrigin="anonymous"
          ></script>
          {/* Plugin view */}
          <form id="sendPayment" role="form" method="post" action="#" style={{ height: "375px", overflow: "auto" }}>
            <div className="row col-lg-6 mx-auto">
              <Grid container className={""} id="header">
                <Grid item style={{ flexGrow: 5, position: "relative" }}>
                  <Typography variant="h5" className={""} style={{ padding: "15px 0 12px 0" }}>
                    Send money
                  </Typography>
                </Grid>
                <Grid item style={{ flexGrow: 1, display: "flex", justifyContent: "right", alignItems: "center" }}>
                  <Fab
                    variant="extended"
                    size="medium"
                    aria-label="History"
                    onClick={(e) => clickHandler(e, HISTORY_PAGE_PATH)}
                    style={{ float: "right", marginRight: "10px" }}
                  >
                    {/* If screen size less than 400 px remove button text */}
                    <FormatListBulletedIcon style={{ marginRight: shrinkButtons ? "0px" : "4px" }} />
                    {shrinkButtons ? null : "History"}
                  </Fab>
                  <Fab
                    variant="extended"
                    size="medium"
                    aria-label="Settings"
                    onClick={(e) => clickHandler(e, SETTINGS_PATH)}
                    style={{ float: "right", marginRight: "0px" }}
                  >
                    {/* If screen size less than 400 px remove button text */}
                    <SettingsIcon style={{ marginRight: shrinkButtons ? "0px" : "4px" }} />
                    {shrinkButtons ? null : "Settings"}
                  </Fab>
                </Grid>
              </Grid>
              <div className="form-group">
                <label htmlFor="recipient">
                  <h6 className="">Recipient Phone/Email</h6>
                </label>
                <input
                  key="recipin"
                  id="recipient"
                  value={recipient}
                  onChange={(e) => {
                    setRecipient(e.target.value);
                  }}
                  type="text"
                  name="recipient"
                  placeholder="Phone/Email"
                  className="form-control"
                  required
                ></input>
              </div>

              <div className="form-group">
                <label htmlFor="amount">
                  <h6>Amount</h6>
                </label>
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon1">
                    $
                  </span>
                  <input
                    key="amountin"
                    value={amount}
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                    id="amount"
                    type="number"
                    min="0.01"
                    step="0.01"
                    className="form-control"
                    placeholder="00.00"
                    aria-label="Amount"
                    aria-describedby="basic-addon1"
                  ></input>
                </div>
              </div>
              <div className="form-group">
                <div className="input-group" style={{ marginTop: "15px" }}>
                  <Fab
                    variant="extended"
                    size="medium"
                    aria-label="Send Button"
                    onClick={(e) => clickHandler(e, MAIN_PAGE_PATH)}
                    style={{ width: "100%" }}
                  >
                    Confirm Payment
                  </Fab>
                </div>
              </div>

              <div className="form-group" style={{ marginTop: "5px" }}>
                {error.length == 0 ? (
                  <div className="input-group">
                    <p className="text-muted">
                      Note: After clicking the 'Confirm Payment' button you will be redirected to a secure gateway for
                      payment. You will also have the option to add a note for your recipient.{" "}
                    </p>
                  </div>
                ) : (
                  <div className="input-group">
                    <p className="text-danger">Error: {error} </p>
                  </div>
                )}
              </div>

              <a id="sendhidden" href="#" target="_top" hidden></a>
            </div>
          </form>
        </div>
      </main>
    </ThemeProvider>
  );
});

export default BannoPlugin;
