import { action, computed, observable, makeObservable } from "mobx";
import { createContext } from "react";
import { FiUiCustomization } from "src/services/generatedApi";
import { fiUiCustomizationApi } from "src/services/api";

export class FiUiCustomizationStore {
  constructor() {
    makeObservable(this);
  }

  @computed public get fiUiCustomization() {
    return this._details;
  }

  @observable public _details: FiUiCustomization = {
    bg_light: "",
    bg_medium: "",
    bg_dark: "",
    text_light: "",
    text_medium: "",
    text_dark: "",
    button_disabled: "",
    button_enabled: "",
    send_on_network_notification: false,
    sms_customization: {
      off_net_first: "",
      off_net_second: "",
      off_net_third: "",
      off_net_fourth: "",
      on_net_first: "",
      on_net_second: "",
      on_net_third: "",
    },
    off_network_bg_primary: "",
    off_network_bg_secondary: "",
    off_network_button_color: "",
    off_network_text_color: "",
  };

  @action public async loadFiUiCustomization() {
    const response = await fiUiCustomizationApi.getUi();
    this._details = response.data;
  }
}

const fiUiCustomizationStore = new FiUiCustomizationStore();
export default createContext(fiUiCustomizationStore);
