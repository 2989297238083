import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Grid, Typography } from "@mui/material";
import { Account, TransferHistoryItem } from "src/services/generatedApi";
import ico_receive from "src/assets/ico_receive.svg";
import AccContext from "src/stores/AccountStore";
import TransferContext from "src/stores/TransferStore";
import { ROUTE_CONSTANTS } from "src/constants";
import { stddatetime } from "src/utils";
import ButtonBack from "./ButtonBack";

const { DEMO_LOGIN_PATH, HISTORY_PAGE_PATH } = ROUTE_CONSTANTS;

interface IURLParams {
  id?: string;
}

const IncomingDetails = observer(({ appStyles }: { appStyles: any }) => {
  const accStore = useContext(AccContext);
  const transferStore = useContext(TransferContext);
  const [account, setAccount] = React.useState<Account | undefined>(undefined);
  const [transfer, setTransfer] = React.useState<TransferHistoryItem | undefined>(undefined);
  const navigate = useNavigate();

  const urlParams: IURLParams = useParams();

  useEffect(() => {
    const loadStores = async () => {
      try {
        await accStore.loadAccounts();
        await transferStore.loadTransfers();
      } catch (error) {
        navigate(DEMO_LOGIN_PATH);
      } finally {
        const t1 = transferStore.history.find(({ id }) => id === urlParams.id);
        setTransfer(t1);
        if (t1) {
          setAccount(accStore.accounts.find(({ id }) => id === t1.account));
        }
      }
    };
    loadStores();
  }, [urlParams.id, accStore, transferStore, navigate]);

  const { renderBackButton } = ButtonBack(HISTORY_PAGE_PATH);

  return (
    <React.Fragment>
      {transfer && (
        <main>
          <Grid container>
            <Grid style={{ ...appStyles.row, ...appStyles.bgDark }} justifyContent="center" container item>
              {renderBackButton()}
              <Typography style={appStyles.title}>Transfer Details</Typography>
            </Grid>
          </Grid>
          <div style={{ ...appStyles.row, ...appStyles.bgMedium, textAlign: "center" }}>
            <Typography component="p" variant="subtitle2">
              {stddatetime(new Date(transfer.timestamp + "Z"))}
            </Typography>
            <Typography component="p" variant="body2" color="secondary.light">
              Incoming transfer
            </Typography>
          </div>
          <div style={{ ...appStyles.row, textAlign: "center" }}>
            <Typography component="p" variant="h6" color="secondary.dark">
              {transfer.sender_name}
            </Typography>
            <div style={{ ...appStyles.circle2, ...appStyles.incomingIcoBg, textAlign: "center" }}>
              <img src={ico_receive} alt="Incoming transfer icon" style={appStyles.icoTransfer2} />
            </div>
            <Typography style={{ marginBottom: "10px" }} component="p" variant="h3" color="secondary.contrastText">
              +${transfer.amount.value}
            </Typography>
            <React.Fragment>
              <Typography component="p" variant="subtitle1" color="secondary.dark">
                {account ? account.name : ""}
              </Typography>
            </React.Fragment>
          </div>
          <div style={{ padding: "0 35px 35px 35px" }}>
            <Typography component="p" variant="subtitle2" color="secondary.light">
              Comment:
            </Typography>
            <Typography component="p" variant="subtitle1" color="secondary.dark">
              <i>{transfer.note}</i>
            </Typography>
          </div>
          <div style={{ padding: "0 35px 70px 35px" }}>
            <Typography component="p" variant="subtitle2" color="secondary.light">
              Reference ID:
            </Typography>
            <Typography component="p" variant="subtitle1" color="secondary.dark">
              <i>{transfer.id}</i>
            </Typography>
          </div>
        </main>
      )}
    </React.Fragment>
  );
});

export default IncomingDetails;
