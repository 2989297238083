import { createTheme } from "@mui/material/styles";
import FiUiCustomizationDetails from "./FiUiCustomizationDetails";

const GlobalTheme = () => {
  const { fiUiCustomizationDetails } = FiUiCustomizationDetails();

  let fi_ui_customization = {
    bg_light: "#ffffff", // white
    bg_medium: "#f6f6f6", // very light gray
    bg_dark: "#eeeeee", // light gray
    button_disabled: "#d5d5d5", // medium gray
    text_light: "#afafaf", // dark gray
    text_medium: "#6a6a6a", // very dark gray
    text_dark: "#000000", // black
    button_enabled: "#778776", // olive gray/green
    send_on_network_notification: false,
    sms_customization: {
      off_net_first: "I am sending you",
      off_net_second: "from my account at",
      off_net_third: "with the note",
      off_net_fourth: "Click this link to receive your funds:",
      on_net_first: "I am sending you",
      on_net_second: "with the note",
      on_net_third: "The funds will be available shortly, and visible via online banking.",
    },
    contrast1: "#ffffff", // white
    contrast2: "#47901a", // bright/money green
    off_network_bg_primary: "#e1e1e1",
    off_network_bg_secondary: "#008000",
    off_network_button_color: "#aa0000",
    off_network_text_color: "#000000",
    event_demo: false,
  };

  const isColor = (str: string) => {
    if (/^#[0-9A-F]{6}$/i.test(str) == true) {
      return true;
    }
  };

  // if any colors are invalid, use defaults from above
  if (
    fiUiCustomizationDetails &&
    isColor(fiUiCustomizationDetails?.bg_light) &&
    isColor(fiUiCustomizationDetails?.bg_medium) &&
    isColor(fiUiCustomizationDetails?.bg_dark) &&
    isColor(fiUiCustomizationDetails?.text_light) &&
    isColor(fiUiCustomizationDetails?.text_medium) &&
    isColor(fiUiCustomizationDetails?.text_dark) &&
    isColor(fiUiCustomizationDetails?.button_disabled) &&
    isColor(fiUiCustomizationDetails?.button_enabled) &&
    isColor(fiUiCustomizationDetails?.off_network_bg_primary) &&
    isColor(fiUiCustomizationDetails?.off_network_bg_secondary) &&
    isColor(fiUiCustomizationDetails?.off_network_button_color) &&
    isColor(fiUiCustomizationDetails?.off_network_text_color)
  ) {
    fi_ui_customization = {
      ...fi_ui_customization,
      bg_light: fiUiCustomizationDetails.bg_light,
      bg_medium: fiUiCustomizationDetails.bg_medium,
      bg_dark: fiUiCustomizationDetails.bg_dark,
      text_light: fiUiCustomizationDetails.text_light,
      text_medium: fiUiCustomizationDetails.text_medium,
      text_dark: fiUiCustomizationDetails.text_dark,
      button_disabled: fiUiCustomizationDetails.button_disabled,
      button_enabled: fiUiCustomizationDetails.button_enabled,
      send_on_network_notification: fiUiCustomizationDetails.send_on_network_notification,
      off_network_bg_primary: fiUiCustomizationDetails.off_network_bg_primary,
      off_network_bg_secondary: fiUiCustomizationDetails.off_network_bg_secondary,
      off_network_button_color: fiUiCustomizationDetails.off_network_button_color,
      off_network_text_color: fiUiCustomizationDetails.off_network_text_color,
    };
  }

  if (fiUiCustomizationDetails?.sms_customization) {
    fi_ui_customization = {
      ...fi_ui_customization,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      sms_customization: fiUiCustomizationDetails.sms_customization,
    };
  }

  if (typeof fiUiCustomizationDetails?.event_demo === "boolean") {
    fi_ui_customization = {
      ...fi_ui_customization,
      event_demo: fiUiCustomizationDetails.event_demo,
    };
  }

  let theme = createTheme({
    palette: {
      primary: {
        light: fi_ui_customization.bg_light,
        main: fi_ui_customization.bg_medium,
        dark: fi_ui_customization.bg_dark,
        contrastText: fi_ui_customization.contrast1,
      },
      secondary: {
        light: fi_ui_customization.text_light,
        main: fi_ui_customization.text_medium,
        dark: fi_ui_customization.text_dark,
        contrastText: fi_ui_customization.contrast2,
      },
      text: {
        primary: fi_ui_customization.text_medium,
      },
      background: {
        default: fi_ui_customization.bg_light,
      },
    },
    typography: {
      fontFamily: ['"SF Pro Display Regular"', "Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
      fontSize: 12,
      subtitle1: {
        fontSize: 16,
        lineHeight: "18px",
      },
      subtitle2: {
        fontSize: 14,
        lineHeight: "16px",
        fontWeight: "normal",
      },
      h3: {
        fontSize: 40,
      },
      h6: {
        fontSize: 16,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            "&:disabled": {
              backgroundColor: fi_ui_customization.button_disabled,
              color: fi_ui_customization.contrast1,
            },
            "&:hover": {
              backgroundColor: fi_ui_customization.button_enabled,
            },
            backgroundColor: fi_ui_customization.button_enabled,
            color: fi_ui_customization.contrast1,
            textTransform: "none",
            fontSize: 16,
            lineHeight: "19px",
            fontWeight: 600,
            padding: "20px 0!important",
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            backgroundColor: fi_ui_customization.bg_light,
            "&.Mui-selected": {
              backgroundColor: fi_ui_customization.bg_medium,
            },
            "&:hover": {
              backgroundColor: fi_ui_customization.bg_dark,
            },
            lineHeight: "2.5rem",
            margin: "-8px 0",
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: fi_ui_customization.text_medium,
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            // this hides the arrows next to the input field
            input: {
              "&[type=number]": {
                MozAppearance: "textfield",
              },
              "&::-webkit-outer-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
              "&::-webkit-inner-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
            },
          },
        },
      },
    },
  });

  // set MUI theme the same as the Receiving App if using the funding service
  if (window.location.pathname.includes("funding")) {
    theme = createTheme({
      palette: {
        background: {
          default: fi_ui_customization.off_network_bg_primary,
        },
      },
    });
  }

  const btnBackTheme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color: fi_ui_customization.text_light,
            "&:hover": {
              backgroundColor: "transparent",
              color: fi_ui_customization.text_medium,
            },
            left: 0,
            position: "absolute",
            marginTop: "-3px",
          },
        },
      },
    },
  });

  return { theme, btnBackTheme, fi_ui_customization };
};
export default GlobalTheme;
