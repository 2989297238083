import { action, computed, observable } from "mobx";
import { createContext } from "react";
import { NewTransferRecipientInner, OutgoingTransfer, TransferHistoryItem } from "../services/generatedApi";
import { transferApi } from "src/services/api";

export class TransferStore {
  // tslint:disable-next-line: variable-name
  @observable private _outgoing: OutgoingTransfer[] = [];
  // tslint:disable-next-line: variable-name
  @observable private _history: TransferHistoryItem[] = [];

  @computed public get history() {
    return this._history;
  }

  public set history(history: TransferHistoryItem[]) {
    this._history = history;
  }

  @action public async loadTransfers() {
    const historyPromise = transferApi.getTransferHistory();
    const history = await historyPromise;
    this._history = history.data.data;
  }

  @action public async postTransfer(
    uuid: string,
    accountId: string,
    card_uuid: string | undefined,
    amount: string,
    recipient: NewTransferRecipientInner[],
    note: string,
  ) {
    const result = await transferApi.postTransfer(uuid, {
      account: accountId,
      card_uuid,
      amount: { type: "USD", value: amount },
      recipient,
      note,
    });
    this._outgoing.push(result.data);
    return result.data;
  }

  @action public async cancelTransfer(transferId: string) {
    await transferApi.deleteTransfer(transferId);
  }

  @action public clear() {
    this._history = [];
  }
}

const transferStore = new TransferStore();
export default createContext(transferStore);
