import React, { useContext, useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import phone from "phone";
import { useNavigate } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { v4 } from "uuid";
import { Grid, Typography, Button, Divider, Input, TextField } from "@mui/material";
import axios from "axios";

import { AppBar } from "src/components";
import { Account, Card } from "src/services/generatedApi";
import ico_send from "src/assets/ico_send.svg";
import AccountContext from "src/stores/AccountStore";
import CardContext from "src/stores/CardStore";
import NotificationContext from "src/stores/NotificationStore";
import TransferContext from "src/stores/TransferStore";

import { Recipient } from "./Recipient";
import { TransferCard } from "./TransferCard";
import { ROUTE_CONSTANTS } from "src/constants";

const { DEMO_LOGIN_PATH, SMS_PENDING } = ROUTE_CONSTANTS;

const SendMoney = observer(
  ({
    appStyles,
    fi_ui_customization,
    setFromDemoPage,
  }: {
    appStyles: any;
    fi_ui_customization: any;
    setFromDemoPage: any;
  }) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const accountStore = useContext(AccountContext);
    const cardStore = useContext(CardContext);
    const transferStore = useContext(TransferContext);
    const notificationStore = useContext(NotificationContext);
    const navigate = useNavigate();

    const requestId = v4();

    //handlers

    const [isLoading, setIsLoading] = React.useState(false);
    const [account, setAccount] = React.useState<Account | undefined>(undefined);
    const [card, setCard] = React.useState<Card | undefined>(undefined);
    const [filteredCards, setFilteredCards] = React.useState<Card[] | undefined>(undefined);
    const [amount, setAmount] = React.useState(params.amount || "");
    const [recipient, setRecipient] = React.useState(params.recipient || "");
    const [recipientError, setRecipientError] = React.useState(false);
    const [disableSendToPhone, setDisableSendToPhone] = React.useState(true);
    const [error, setError] = React.useState<undefined | string>(undefined);
    const [isValidInput, setIsValidInput] = useState(false);

    useEffect(() => {
      setFromDemoPage(false);
    }, []);

    const filterCards = (account: Account) => {
      const filtered = cardStore.getFilteredCards(account);
      setFilteredCards(filtered);
      setCard(filtered[0]);
    };

    const getOS = () => {
      const userAgent = window.navigator.userAgent.toLowerCase(),
        macosPlatforms = /(mac)/i,
        windowsPlatforms = /(win32|win64|windows|wince)/i,
        iosPlatforms = /(iphone|ipad|ipod)/i;

      let os = null;

      if (macosPlatforms.test(userAgent)) {
        os = "macos";
      } else if (iosPlatforms.test(userAgent)) {
        os = "ios";
      } else if (windowsPlatforms.test(userAgent)) {
        os = "windows";
      } else if (/android/.test(userAgent)) {
        os = "android";
      } else if (!os && /linux/.test(userAgent)) {
        os = "linux";
      } else {
        os = "unknown";
      }

      return os;
    };

    const isMobile = () => {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };

    const validateRecipient = () => {
      setRecipientError(false);
      const recipientType = recipient.includes("@") ? "email" : "phone";

      let recipientValue = recipient.trim().toLowerCase();
      if (recipientType === "phone") {
        if (disableSendToPhone) {
          setRecipientError(true);
          return [];
        }

        const parsed = phone(recipientValue, { country: "USA" });
        if (!parsed.isValid) {
          setRecipientError(true);
          return [];
        }
        recipientValue = parsed.phoneNumber;
      } else if (recipientType === "email") {
        const e =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!e.test(recipientValue)) {
          setRecipientError(true);
          return [];
        }
      }
      return [{ type: recipientType, value: recipientValue }];
    };

    const validateAmount = () => {
      let newError = false;

      const amtVal = parseFloat(amount);
      if (amtVal < 0.0) {
        newError = true;
      }

      // return false = valid, no errors
      return !newError;
    };

    const handleRecipientChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
      setRecipientError(false);
      setRecipient(event.target.value ? event.target.value : "");
      if (
        (event.target.value.match(/^[0-9, +, \-, (, )]+$/) != null && event.target.value.length > 9) ||
        event.target.value
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          )
      ) {
        setIsValidInput(true);
      } else {
        setIsValidInput(false);
      }
    };

    const handleAmountChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
      setAmount(event.target.value ? event.target.value : "");
    };

    const flashError = (e: string) => {
      setError(e);
      setTimeout(() => {
        setError(undefined);
      }, 3000);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      setIsLoading(true);
      setError(undefined);
      event.preventDefault();
      const postData = new FormData(event.target as HTMLFormElement);
      const note = (postData.get("note") as string).trim();
      if (account) {
        try {
          // TODO: 📚 if it becomes possible for a user to select multiple
          // recipient contact items (for example, using the address book
          // to pull a contact that has multiple emials and/or phone numbers)
          // then build the recipient array from that. For now, use an array
          // of one to represent the current text field.

          const wrappedRecipient = validateRecipient();

          if (wrappedRecipient.length < 1) {
            let errMsg = "Invalid email";
            if (!disableSendToPhone) {
              errMsg = "Invalid email or phone number";
            }
            flashError(errMsg);
            return;
          }

          if (!validateAmount()) {
            flashError("Invalid amount");
            return;
          }

          const response = await transferStore.postTransfer(
            requestId,
            account.id,
            card?.uuid,
            amount,
            wrappedRecipient,
            note,
          );

          const transferId = response.id;
          notificationStore.isLoading = true;
          notificationStore.inProgressTransferId = transferId;
          navigate(formatRoute(SMS_PENDING, { id: response.id }));
        } catch (err) {
          if (axios.isAxiosError(err) && err.response) {
            // Access to config, request, and response
            console.log(err.response);
            flashError(err.response?.data?.detail);
          } else {
            console.log("An unknown error occurred");
            flashError("An error ocurred while sending these funds.");
          }
        } finally {
          setIsLoading(false);
        }
      }
    };

    useEffect(() => {
      // If devices user agent is a mobile or mac device
      // we want to allow sending to phone numbers
      // Otherwise disable sending to sms
      const os = getOS();
      if (os === "macos" || os === "ios" || isMobile()) {
        setDisableSendToPhone(false);
      }
      const loadStores = async () => {
        try {
          await accountStore.loadAccounts();
          await cardStore.loadCards();
          await notificationStore.loadNotifications();
        } catch (_err) {
          navigate(DEMO_LOGIN_PATH);
        } finally {
          setAccount(accountStore.accounts[0]);
          filterCards(accountStore.accounts[0]);
          notificationStore.resetPhoneTransactions();
        }
      };
      loadStores();
    }, [notificationStore, cardStore, accountStore, navigate]);

    let submitBtnTxt = "Continue: enter amount";
    if (!recipient || recipientError) {
      submitBtnTxt = "Enter recipient's email";
      if (!disableSendToPhone) {
        submitBtnTxt = "Enter recipient's phone number or email";
      }
    } else if (amount && recipient) {
      submitBtnTxt = "Send Payment Now";
    }

    return (
      <React.Fragment>
        <main>
          <AppBar appStyles={appStyles} fi_ui_customization={fi_ui_customization} />
          <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
            <Recipient
              handleRecipientChange={handleRecipientChange}
              setRecipient={setRecipient}
              recipientError={recipientError}
              recipient={recipient}
              disableSendToPhone={disableSendToPhone}
              appStyles={appStyles}
            />
            <div style={{ padding: "20px 0 10px 0" }}>
              <Grid container spacing={1}>
                <Grid item>
                  <div style={{ ...appStyles.circle, ...appStyles.outgoingIcoBg2, textAlign: "center" }}>
                    <img src={ico_send} alt="Outgoing transfer icon" style={appStyles.icoTransfer} />
                  </div>
                </Grid>
                <Grid item>
                  <Typography style={appStyles.amountSign}>$</Typography>
                  <Input
                    value={amount}
                    type="number"
                    style={appStyles.amountField}
                    disableUnderline
                    onChange={handleAmountChange()}
                    onClick={() => validateRecipient()}
                    placeholder="0"
                    inputProps={{ min: "0" }}
                  />
                </Grid>
              </Grid>
            </div>
            <Divider />
            <TransferCard
              filterCards={filterCards}
              filteredCards={filteredCards}
              setAccount={setAccount}
              account={account}
              accounts={accountStore.accounts}
              card={card}
              setCard={setCard}
            />
            <div
              style={{
                ...appStyles.row2,
                ...appStyles.bgMedium,
                position: "relative",
                paddingBottom: "48px",
              }}
            >
              <TextField
                variant="standard"
                fullWidth
                label="Optional comment"
                name="note"
                placeholder=""
                multiline
                InputProps={{ style: { ...appStyles.inputRoot } }}
                InputLabelProps={{
                  style: { ...appStyles.labelRoot },
                }}
              />
            </div>
            <div style={{ textAlign: "center" }}>
              {error && (
                <React.Fragment>
                  <div style={{ ...appStyles.row, ...appStyles.bgMedium, textAlign: "center" }}>
                    <Typography component="p" variant="subtitle1">
                      {error}
                    </Typography>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div style={appStyles.buttonHolder}>
              <Button
                disabled={!amount || !recipient || recipientError || isLoading || !isValidInput}
                type="submit"
                fullWidth
              >
                {submitBtnTxt}
              </Button>
            </div>
          </form>
        </main>
      </React.Fragment>
    );
  },
);

export default SendMoney;
