import { action, computed, observable } from "mobx";
import { createContext } from "react";

import { Account, AccountChanges } from "src/services/generatedApi";
import { accountApi } from "src/services/api";

export class AccountStore {
  // tslint:disable-next-line: variable-name
  @observable private _accounts: Account[] = [];
  @observable private _accountChanges: AccountChanges[] = [];

  @computed public get accounts() {
    return this._accounts;
  }

  public set accounts(accounts: Account[]) {
    this._accounts = accounts;
  }

  @computed public get accountChanges() {
    return this._accountChanges;
  }

  public set accountChanges(accountChanges: AccountChanges[]) {
    this._accountChanges = accountChanges;
  }

  @action public async loadAccounts() {
    const response = await accountApi.getAccounts();
    this._accounts = response.data;
  }

  @action public async loadAccountChanges() {
    const response = await accountApi.getAccountChanges();
    this._accountChanges = response.data;
  }

  @action public clear() {
    this._accounts = [];
    this._accountChanges = [];
  }
}

const accountStore = new AccountStore();
export default createContext(accountStore);
