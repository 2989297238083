import React, { useContext, useEffect, useState } from "react";
import { Grid, Typography, Divider, List } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { AccountChanges as AccountChangesType, TransferHistoryItem } from "src/services/generatedApi";
import TransferContext from "src/stores/TransferStore";
import AccountContext from "src/stores/AccountStore";
import { ROUTE_CONSTANTS } from "src/constants";
import ButtonBack from "src/components/App/ButtonBack";
import { stddate } from "src/utils";
import OutgoingTransfer from "./OutgoingTransfer";
import IncomingTransfer from "./IncomingTransfer";
import AccountChange from "./AccountChange";

const { DEMO_LOGIN_PATH, MAIN_PAGE_PATH, NP_EVENT_DEMO } = ROUTE_CONSTANTS;

const PathApp = observer(({ appStyles, fromDemoPage }: { appStyles: any; fromDemoPage: boolean }) => {
  const transferStore = useContext(TransferContext);
  const accountStore = useContext(AccountContext);
  const navigate = useNavigate();

  const [transfersLoaded, setTransfersLoaded] = useState(false);
  const [accountLoaded, setAccountLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadStores = async () => {
      setIsLoading(true);
      try {
        await accountStore.loadAccountChanges().then(() => {
          setAccountLoaded(true);
        });
        await transferStore.loadTransfers().then(() => {
          setTransfersLoaded(true);
        });
      } catch (error) {
        navigate(DEMO_LOGIN_PATH);
      } finally {
        setIsLoading(false);
      }
    };
    loadStores();
  }, [transferStore, accountStore, navigate]);

  const handleLinkClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, path: string) => {
    navigate(path);
  };

  type Notification = { type: string; notification: AccountChangesType | TransferHistoryItem };
  const transfersByDay = (): Map<string, Notification[]> => {
    const days: Map<string, Notification[]> = new Map();

    transferStore.history.forEach((transfer) => {
      const day = stddate(new Date(transfer.timestamp + "Z"));
      if (!days.has(day)) {
        days.set(day, []);
      }
      days.get(day)!.push({ type: "transfer", notification: transfer });
    });

    accountStore.accountChanges.forEach((accountChange) => {
      const ts = new Date(accountChange.timestamp * 1000);
      const day = ts.getFullYear() + "-" + (ts.getMonth() + 1) + "-" + ts.getDate();
      if (!days.has(day)) {
        days.set(day, []);
      }
      days.get(day)!.push({ type: "account_change", notification: accountChange });
    });

    // sort transfers within each day
    days.forEach((dayTransfers) => {
      dayTransfers.sort((a, b) => {
        if (a.notification.timestamp > b.notification.timestamp) {
          return -1;
        }
        if (a.notification.timestamp < b.notification.timestamp) {
          return 1;
        }
        return 0;
      });
    });

    return days;
  };

  let backPath = MAIN_PAGE_PATH;

  if (fromDemoPage) {
    backPath = NP_EVENT_DEMO;
  }

  const { renderBackButton } = ButtonBack(backPath);

  const sortedTransactions = Array.from(transfersByDay());

  return (
    <React.Fragment>
      <main>
        <Grid container>
          <Grid
            style={{ ...appStyles.row2, ...appStyles.bgDark, position: "relative" }}
            justifyContent="center"
            container
            item
          >
            {renderBackButton()}
            <Typography style={appStyles.title}>History</Typography>
          </Grid>
        </Grid>
        {transfersLoaded && accountLoaded ? (
          sortedTransactions.map(([day, transactions]) => {
            return (
              <React.Fragment key={day}>
                <Grid container>
                  <Grid style={{ padding: "15px 0 0 0" }} justifyContent="center" container item>
                    <Typography>{day.slice(5)}</Typography>
                  </Grid>
                </Grid>
                <List style={appStyles.list}>
                  {transactions.map((transaction: Notification) => {
                    switch (transaction.type) {
                      case "account_change": {
                        const accountChange = transaction.notification as AccountChangesType;
                        return (
                          <AccountChange
                            key={accountChange.timestamp.toString()}
                            notification={accountChange}
                            appStyles={appStyles}
                          />
                        );
                      }
                      case "transfer": {
                        const transfer = transaction.notification as TransferHistoryItem;
                        switch (transfer.outgoing) {
                          case true: {
                            return (
                              <OutgoingTransfer
                                outgoing={transfer}
                                key={transfer.id}
                                handleLinkClick={handleLinkClick}
                                appStyles={appStyles}
                              />
                            );
                          }
                          case false: {
                            return (
                              <IncomingTransfer
                                incoming={transfer}
                                key={transfer.id}
                                handleLinkClick={handleLinkClick}
                                appStyles={appStyles}
                              />
                            );
                          }
                        }
                      }
                    }
                  })}
                  <Divider style={{ margin: "10px 0" }} component="li" />
                </List>
              </React.Fragment>
            );
          })
        ) : (
          <>
            {isLoading ? (
              <Typography variant="h6" style={{ textAlign: "center" }}>
                Transfer history data is loading.
              </Typography>
            ) : (
              <Typography variant="h6" style={{ textAlign: "center" }}>
                Something went wrong while fetching transfer history.
              </Typography>
            )}
          </>
        )}
      </main>
    </React.Fragment>
  );
});

export default PathApp;
