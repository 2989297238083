import React from "react";
import { Typography, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { AccountChanges as AccountChangesType } from "src/services/generatedApi";
import ico_settings_2 from "src/assets/ico_settings_2.svg";

const AccountChange = ({ notification, appStyles }: { notification: AccountChangesType; appStyles: any }) => {
  return (
    <ListItem>
      <ListItemAvatar>
        <div style={{ ...appStyles.circle, ...appStyles.outgoingIcoBg, textAlign: "center" }}>
          <img src={ico_settings_2} alt="Settings icon" style={appStyles.icoTransfer3} />
        </div>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography component="p" color="secondary.light">
            Settings
          </Typography>
        }
        secondary={
          <Typography component="p" variant="subtitle2">
            The default account to receive funds for {notification.identifier_value} has been changed to another
            institution
          </Typography>
        }
      />
    </ListItem>
  );
};

export default AccountChange;
