import { action } from "mobx";
import { createContext } from "react";
import { sessionApi } from "../services/api";

export class AppStore {
  private window: WindowLocalStorage;

  constructor(window: WindowLocalStorage) {
    this.window = window;
  }

  public async ssoLogIn(token: string) {
    const res = await sessionApi.createSsoSession({ token });
    if (!(res && 200 === res.status)) {
      throw new Error("Log in failed");
    }
    this.window.localStorage.setItem("loginType", "sso");
    return res.data;
  }

  @action public async logOut() {
    await sessionApi.destroySession();
    this.clear();
  }

  public clear() {
    this.window.localStorage.clear();
  }
}

const appStore = new AppStore(window);
export default createContext(appStore);
