import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { formatRoute } from "react-router-named-routes";
import { Grid, Typography, List, ListItem, ListItemText } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { ROUTE_CONSTANTS } from "src/constants";
import { Identifier } from "src/services/generatedApi";
import AccountContext from "src/stores/AccountStore";
import IdentifierContext from "src/stores/IdentifierStore";
import ButtonBack from "src/components/App/ButtonBack";

const { DEMO_LOGIN_PATH, MAIN_PAGE_PATH, SET_DEFAULT_ACCOUNT_PATH } = ROUTE_CONSTANTS;

const Settings = observer(({ appStyles }: { appStyles: any }) => {
  const accountStore = useContext(AccountContext);
  const identifierStore = useContext(IdentifierContext);
  const navigate = useNavigate();

  const [identifiersLoaded, setIdentifiersLoaded] = useState(false);
  const [defaultAccountsLoaded, setDefaultAccountsLoaded] = useState(false);
  const [accountsLoaded, setAccountsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadStores = async () => {
      setIsLoading(true);
      try {
        await identifierStore.loadIdentifiers().then(() => {
          setIdentifiersLoaded(true);
        });
        await identifierStore.loadDefaultAccounts().then(() => {
          setDefaultAccountsLoaded(true);
        });
        await accountStore.loadAccounts().then(() => {
          setAccountsLoaded(true);
        });
      } catch (error) {
        navigate(DEMO_LOGIN_PATH);
      } finally {
        setIsLoading(false);
      }
    };
    loadStores();
  }, [identifierStore, accountStore, navigate]);

  const identifierItem = (identifier: Identifier, type: string, id: number) => {
    if (identifier && type === identifier.type) {
      return (
        <ListItem onClick={() => navigate(formatRoute(SET_DEFAULT_ACCOUNT_PATH, { id }))}>
          <ListItemText
            primary={
              <Typography component="p" variant="subtitle1" color="secondary.dark">
                {identifier.value}
              </Typography>
            }
            secondary={
              <Typography component="p" variant="subtitle2" color="secondary.light">
                {(() => {
                  const defaultAccountId = identifierStore.getDefaultAccount(identifier.type, identifier.value);
                  if (defaultAccountId) {
                    const account = accountStore.accounts.find(({ id }) => id === defaultAccountId);
                    if (account) {
                      return account.name;
                    }
                  }

                  return "Another account and/or another institution";
                })()}
              </Typography>
            }
          />
          <ListItemText style={{ textAlign: "right" }} primary={<KeyboardArrowRightIcon />} />
        </ListItem>
      );
    } else {
      return null;
    }
  };

  const { renderBackButton } = ButtonBack(MAIN_PAGE_PATH);

  return (
    <React.Fragment>
      <main>
        <Grid container>
          <Grid
            style={{ ...appStyles.row2, ...appStyles.bgDark, position: "relative" }}
            justifyContent="center"
            container
            item
          >
            {renderBackButton()}
            <Typography style={appStyles.title}>Settings</Typography>
          </Grid>
        </Grid>
        {identifiersLoaded && defaultAccountsLoaded && accountsLoaded ? (
          <>
            <div style={{ ...appStyles.row3, ...appStyles.bgMedium }}>
              <Typography component="p" variant="subtitle2" color="secondary.light">
                Default accounts for emails
              </Typography>
            </div>
            <List style={appStyles.list}>
              {identifierStore.identifiers.map((identifier: Identifier, index: number) => {
                return <React.Fragment key={index}>{identifierItem(identifier, "email", index)}</React.Fragment>;
              })}
            </List>
            <div style={{ ...appStyles.row3, ...appStyles.bgMedium }}>
              <Typography component="p" variant="subtitle2" color="secondary.light">
                Default accounts for phone numbers
              </Typography>
            </div>
            <List style={appStyles.list}>
              {identifierStore.identifiers.map((identifier: Identifier, index: number) => {
                return <React.Fragment key={index}>{identifierItem(identifier, "phone", index)}</React.Fragment>;
              })}
            </List>
          </>
        ) : (
          <>
            {isLoading ? (
              <Typography variant="h6" style={{ textAlign: "center" }}>
                Account and settings data is loading.
              </Typography>
            ) : (
              <Typography variant="h6" style={{ textAlign: "center" }}>
                Something went wrong while fetching account and settings data.
              </Typography>
            )}
          </>
        )}
      </main>
    </React.Fragment>
  );
});

export default Settings;
