import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";

import { ROUTE_CONSTANTS } from "src/constants";
import { Account, TransferHistoryItem } from "src/services/generatedApi";
import ico_failed from "src/assets/ico_failed.svg";
import AccContext from "src/stores/AccountStore";
import TransferContext from "src/stores/TransferStore";
import NotificationContext from "src/stores/NotificationStore";

import { stddatetime } from "src/utils";

const { DEMO_LOGIN_PATH, MAIN_PAGE_PATH, NP_EVENT_DEMO } = ROUTE_CONSTANTS;

interface IURLParams {
  id?: string;
}

const TransferFailed = observer(({ appStyles, fromDemoPage }: { appStyles: any; fromDemoPage: boolean }) => {
  const accStore = useContext(AccContext);
  const transferStore = useContext(TransferContext);
  const notificationStore = useContext(NotificationContext);
  const [account, setAccount] = React.useState<Account | undefined>(undefined);
  const [transfer, setTransfer] = React.useState<TransferHistoryItem | undefined>(undefined);
  const navigate = useNavigate();

  const urlParams: IURLParams = useParams();

  const statusInfo = () => {
    if (transfer && "PENDING" !== transfer.status) {
      return (
        <Typography component="p" variant="body2" color="secondary.light">
          Outgoing transfer failed
        </Typography>
      );
    }
  };

  useEffect(() => {
    const loadStores = async () => {
      try {
        await accStore.loadAccounts();
        await transferStore.loadTransfers();
      } catch (error) {
        navigate(DEMO_LOGIN_PATH);
      } finally {
        const t1 = transferStore.history.find(({ id }) => id === urlParams.id);
        setTransfer(t1);
        if (t1) {
          setAccount(accStore.accounts.find(({ id }) => id === t1.account));
        }
      }
    };
    loadStores();
    return () => {
      notificationStore.resetPhoneTransactions();
    };
  }, [urlParams.id, accStore, transferStore, navigate, notificationStore]);

  let backPath = MAIN_PAGE_PATH;

  if (fromDemoPage) {
    backPath = NP_EVENT_DEMO;
  }

  return (
    <React.Fragment>
      {transfer && (
        <main>
          <Grid style={{ ...appStyles.row, ...appStyles.bgDark }} justifyContent="center" container item>
            <Typography style={{ ...appStyles.title }}>Money Not Sent</Typography>
          </Grid>
          <div style={{ ...appStyles.row, ...appStyles.bgMedium, textAlign: "center" }}>
            <Typography component="p" variant="subtitle2">
              {stddatetime(new Date(transfer.timestamp + "Z"))}
            </Typography>
            {statusInfo()}
          </div>
          <div style={{ ...appStyles.row, textAlign: "center" }}>
            <Typography component="p" variant="h6" color="secondary.dark">
              {transfer.recipient.value}
            </Typography>
            <div
              style={
                transfer.status === "PENDING"
                  ? {
                      ...appStyles.circle2,
                      ...appStyles.outgoingIcoBg,
                      ...appStyles.pendingIcoBg,
                      textAlign: "center",
                    }
                  : { ...appStyles.circle2, ...appStyles.outgoingIcoBg, textAlign: "center" }
              }
            >
              <img src={ico_failed} alt="Failed transfer icon" style={appStyles.icoTransfer2} />
            </div>
            <Typography style={{ marginBottom: "10px" }} component="p" variant="h3" color="secondary.dark">
              -${transfer.amount.value}
            </Typography>
            <React.Fragment>
              <Typography component="p" variant="subtitle1" color="secondary.dark">
                {account ? account.name : ""}
              </Typography>
            </React.Fragment>
          </div>
          <div style={{ padding: "0 35px 35px 35px" }}>
            <Typography component="p" variant="subtitle2" color="secondary.light">
              Comment
            </Typography>
            <Typography component="p" variant="subtitle1" color="secondary.dark">
              <i>{transfer.note}</i>
            </Typography>
          </div>
          <div style={{ padding: "0 35px 150px 35px" }}>
            <Typography component="p" variant="subtitle2" color="secondary.light">
              Reference ID:
            </Typography>
            <Typography component="p" variant="subtitle1" color="secondary.dark">
              <i>{transfer.id}</i>
            </Typography>
          </div>
          <div style={{ position: "fixed", bottom: 0, width: "100%", textAlign: "center" }}>
            <Grid container>
              <Button fullWidth onClick={() => navigate(backPath)} style={{ borderRadius: 0, height: "60px" }}>
                Return Home
              </Button>
            </Grid>
          </div>
        </main>
      )}
    </React.Fragment>
  );
});

export default TransferFailed;
