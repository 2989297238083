import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import GlobalTheme from "src/theme";
import { ThemeProvider } from "@mui/material/styles";

const ButtonBack = (path: string) => {
  const { btnBackTheme } = GlobalTheme();

  const navigate = useNavigate();

  const renderBackButton = () => {
    return (
      <ThemeProvider theme={btnBackTheme}>
        <Button onClick={() => navigate(path)}>
          <ArrowBackIosIcon fontSize="small" />
        </Button>
      </ThemeProvider>
    );
  };
  return { renderBackButton };
};

export default ButtonBack;
