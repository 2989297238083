import { ApiConf } from "src/config";
import {
  AccountApi,
  IdentifierApi,
  NotificationApi,
  SessionApi,
  TransferApi,
  CardApi,
  FiUiCustomizationApi,
} from "src/services/generatedApi";

const sessionApi = new SessionApi(ApiConf);
const accountApi = new AccountApi(ApiConf);
const identifierApi = new IdentifierApi(ApiConf);
const notificationApi = new NotificationApi(ApiConf);
const transferApi = new TransferApi(ApiConf);
const cardApi = new CardApi(ApiConf);
const fiUiCustomizationApi = new FiUiCustomizationApi(ApiConf);

export { sessionApi, accountApi, identifierApi, notificationApi, transferApi, cardApi, fiUiCustomizationApi };
