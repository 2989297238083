import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { Grid, CircularProgress, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import NotificationContext from "src/stores/NotificationStore";
import TransferContext from "src/stores/TransferStore";
import { ROUTE_CONSTANTS } from "src/constants";
import { TransferHistoryItem } from "src/services/generatedApi";

const { DEMO_LOGIN_PATH, MONEY_SENT_PATH, MONEY_NOT_SENT_PATH, MAIN_PAGE_PATH, NP_EVENT_DEMO } = ROUTE_CONSTANTS;

interface IURLParams {
  id?: string;
}

const SMSProcessing = ({ appStyles, fromDemoPage }: { appStyles: any; fromDemoPage: boolean }) => {
  const notificationStore = useContext(NotificationContext);
  const transferStore = useContext(TransferContext);
  const navigate = useNavigate();
  const urlParams: IURLParams = useParams();
  const [status, setStatus] = useState("processing");
  const [transfer, setTransfer] = React.useState<TransferHistoryItem | undefined>(undefined);

  useEffect(() => {
    const loadStores = async () => {
      try {
        await notificationStore.loadNotifications();
        await transferStore.loadTransfers();
      } catch (_err) {
        navigate(DEMO_LOGIN_PATH);
      }
    };
    loadStores();

    const interval = setInterval(() => {
      if (
        notificationStore.notifications.find((one) => one.data.transfer_id === urlParams.id)?.data.status === "failed"
      ) {
        clearInterval(interval);
        navigate(formatRoute(MONEY_NOT_SENT_PATH, { id: urlParams.id }));
      } else if (
        notificationStore.notifications.find((one) => one.data.transfer_id === urlParams.id)?.data.status ===
          "succeeded" ||
        notificationStore.notifications.find((one) => one.data.transfer_id === urlParams.id)?.data.shortlink
      ) {
        clearInterval(interval);
        navigate(formatRoute(MONEY_SENT_PATH, { id: urlParams.id }));
      }
    }, 1000);
    setTimeout(() => {
      clearInterval(interval);
      setStatus("timeout");
    }, 20000);
  }, [notificationStore, navigate]);

  useEffect(() => {
    const t1 = transferStore.history.find(({ id }) => id === urlParams.id);
    setTransfer(t1);
  }, [transferStore.history]);

  let backPath = MAIN_PAGE_PATH;

  if (fromDemoPage) {
    backPath = NP_EVENT_DEMO;
  }

  return (
    <React.Fragment>
      {status === "processing" ? (
        <Grid container alignItems="center" justifyContent="center" direction="column" style={{ height: "100%" }}>
          <CircularProgress />
          <h1>Processing Transaction</h1>
        </Grid>
      ) : (
        <>
          {transfer && status === "timeout" && (
            <main>
              <Grid style={{ ...appStyles.row, ...appStyles.bgDark }} justifyContent="center" container item>
                <Typography component="div" style={{ ...appStyles.title, padding: 10 }}>
                  Your transfer is still processing. Please check the status in the 'History' tab later.
                  <p style={{ marginTop: "20px" }}>
                    {transfer.recipient.type === "phone" ? (
                      <>
                        You must return later to send a message to the receiver in order for this transaction to be
                        receivable.
                      </>
                    ) : (
                      <>An email will be sent to the recipient once the transfer has processed.</>
                    )}
                  </p>
                </Typography>
              </Grid>
              <div style={{ ...appStyles.row, textAlign: "center" }}>
                <Typography style={appStyles.title}>Transfer Details:</Typography>
                <Typography component="div" variant="h6" color="secondary.dark">
                  <p>To</p>
                  <p style={{ fontWeight: 600 }}>{transfer.recipient?.value}</p>
                  <p>Amount</p>
                  <p style={{ fontWeight: 600 }}>${transfer.amount?.value}</p>
                </Typography>
              </div>
              <div style={{ padding: "0 35px 35px 35px" }}>
                <Typography component="p" variant="subtitle2" color="secondary.dark">
                  Comment
                </Typography>
                <Typography component="p" variant="subtitle1" color="secondary.dark">
                  <i>{transfer.note}</i>
                </Typography>
              </div>
              <div style={{ padding: "0 35px 150px 35px" }}>
                <Typography component="p" variant="subtitle2" color="secondary.dark">
                  Reference ID:
                </Typography>
                <Typography component="p" variant="subtitle1" color="secondary.dark">
                  <i>{transfer.id}</i>
                </Typography>
              </div>
              <div style={{ position: "fixed", bottom: 0, width: "100%", textAlign: "center" }}>
                <Grid container>
                  <Button fullWidth onClick={() => navigate(backPath)} style={{ borderRadius: 0, height: "60px" }}>
                    Return Home
                  </Button>
                </Grid>
              </div>
            </main>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default SMSProcessing;
