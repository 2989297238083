import React from "react";

import { Typography, TextField, InputAdornment } from "@mui/material";

import ico_close from "src/assets/close.svg";

export const Recipient = ({
  handleRecipientChange,
  setRecipient,
  recipientError,
  recipient,
  disableSendToPhone,
  appStyles,
}: {
  handleRecipientChange: () => void;
  setRecipient: (arg0: string) => void;
  recipientError: boolean | undefined;
  recipient: string;
  disableSendToPhone: boolean;
  appStyles: any;
}) => {
  return (
    <React.Fragment>
      <div style={{ ...appStyles.row2, ...appStyles.bgMedium, position: "relative" }}>
        <span style={{ ...appStyles.triangleB, position: "absolute" }}>&nbsp;</span>
        <TextField
          variant="standard"
          value={recipient}
          fullWidth
          error={recipientError}
          label={disableSendToPhone ? "Recipient's email" : "Recipient's phone number or email"}
          InputProps={{
            style: { ...appStyles.inputRoot },
            endAdornment: (
              <InputAdornment position="end">
                {recipient && <img onClick={() => setRecipient("")} src={ico_close} alt="Reset Recipient Icon" />}
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            style: { ...appStyles.labelRoot },
          }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange={handleRecipientChange()}
        />
      </div>
      {recipientError && recipient && (
        <div style={{ ...appStyles.errorInput, position: "relative" }}>
          <span style={{ ...appStyles.triangleT, position: "absolute" }}>&nbsp;</span>
          <Typography>Please enter a valid email {!disableSendToPhone ? "or phone number" : null}</Typography>
        </div>
      )}
    </React.Fragment>
  );
};
