import React from "react";
import { formatRoute } from "react-router-named-routes";
import { Typography, ListItem, ListItemAvatar, ListItemText, Tooltip } from "@mui/material";
import { TransferHistoryItem as TransferHistoryItemType } from "src/services/generatedApi";
import { ROUTE_CONSTANTS } from "src/constants";
import ico_failed from "src/assets/ico_failed.svg";
import ico_send from "src/assets/ico_send.svg";
import ico_time from "src/assets/ico_time.svg";

const { OUTGOING_DETAILS_PATH } = ROUTE_CONSTANTS;

const OutgoingTransfer = ({
  outgoing,
  handleLinkClick,
  appStyles,
}: {
  outgoing: TransferHistoryItemType;
  handleLinkClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, path: string) => void;
  appStyles: any;
}) => {
  return (
    <ListItem
      button
      onClick={(event) => handleLinkClick(event, formatRoute(OUTGOING_DETAILS_PATH, { id: outgoing.id }))}
    >
      <ListItemAvatar>
        <div
          style={
            outgoing.status === "PENDING"
              ? {
                  ...appStyles.circle,
                  ...appStyles.outgoingIcoBg,
                  ...appStyles.pendingIcoBg,
                  textAlign: "center",
                }
              : {
                  ...appStyles.circle,
                  ...appStyles.outgoingIcoBg,
                  textAlign: "center",
                }
          }
        >
          <img src={ico_send} alt="Outgoing transfer icon" style={appStyles.icoTransfer} />
        </div>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography component="p" variant="body2" color="secondary.light">
            Outgoing transfer
          </Typography>
        }
        secondary={
          <Typography noWrap component="p" variant="body1">
            {outgoing.recipient.value}
          </Typography>
        }
      />
      <ListItemText
        primary={
          <React.Fragment>
            <Typography
              style={
                outgoing.status === "SUCCEEDED"
                  ? { ...appStyles.amount, display: "flex", float: "right", ...appStyles.black }
                  : { ...appStyles.amount, display: "flex", float: "right" }
              }
            >
              {(() => {
                switch (outgoing.status) {
                  case "PENDING":
                    return (
                      <Tooltip title="Pending">
                        <span style={{ ...appStyles.circle, ...appStyles.transferPendingBg, textAlign: "center" }}>
                          <img src={ico_time} alt="Transfer pending icon" />
                        </span>
                      </Tooltip>
                    );
                  case "FAILED":
                    return (
                      <Tooltip title="Failed">
                        <span style={{ ...appStyles.circle, ...appStyles.transferPendingBg, textAlign: "center" }}>
                          <img src={ico_failed} alt="Transfer failed icon" />
                        </span>
                      </Tooltip>
                    );
                }
              })()}
              -${outgoing.amount.value}
            </Typography>
          </React.Fragment>
        }
      />
    </ListItem>
  );
};

export default OutgoingTransfer;
