import { connect } from "getstream";
import { action, computed, observable, reaction } from "mobx";
import { createContext } from "react";
import { notificationApi } from "src/services/api";

export interface INotification {
  id: string;
  timestamp: number;
  type: string;
  msg: string;
  is_read: boolean;
  is_seen: boolean;
  transfer_id: string;
  data: any;
  verb: string;
}

export class NotificationStore {
  @observable public isLoading: boolean = false;
  @observable public cancelTransferSuccessful: boolean = false;

  private feed?: any;
  private subscription?: any;
  private _inProgressTransferId?: string | undefined | null = null;
  private _cancelingTransferId?: string | undefined | null = null;
  private _recentPhoneTransaction?: any = null;

  // tslint:disable-next-line: variable-name
  @observable private _notifications: INotification[] = [];

  @computed public get notifications() {
    return this._notifications;
  }

  public set notifications(notifications: INotification[]) {
    this._notifications = notifications;
  }

  public set inProgressTransferId(transferId: string | null) {
    this._inProgressTransferId = transferId;
  }

  public set cancelingTransferId(transferId: string | null) {
    this._cancelingTransferId = transferId;
  }

  @computed public get recentPhoneTransaction() {
    return this._recentPhoneTransaction;
  }

  public set recentPhoneTransaction(transaction: any) {
    this._recentPhoneTransaction = transaction;
  }

  @computed public get isPhoneTransactionOffNetwork() {
    return this._recentPhoneTransaction ? this._recentPhoneTransaction.verb === "shortlink_generated" : false;
  }

  public shortLinkTransferReaction = reaction(
    () => this.notifications,
    (notifications) => {
      const foundNotification = notifications.filter((n: INotification) => {
        return n.transfer_id === this._inProgressTransferId;
      })[0];

      if (foundNotification) {
        this.isLoading = false;
        this.recentPhoneTransaction = foundNotification;
      }
    },
  );

  public cancelTransactionReaction = reaction(
    () => this.notifications,
    (notifications) => {
      const foundNotification = notifications.filter((n: INotification) => {
        return n.transfer_id === this._cancelingTransferId;
      })[0];
      if (foundNotification) {
        this.cancelTransferSuccessful = true;
      }
    },
  );

  @computed public get unseen() {
    return this._notifications.filter((n: INotification) => {
      return !n.is_seen;
    }).length;
  }

  @action public async loadNotifications() {
    await this.loadNotificationsInternal(true);
  }

  @action public async markAllRead() {
    for (const n of this.notifications) {
      if (this.feed) {
        if (!n.is_seen || !n.is_read) {
          await this.feed.getActivityDetail(n.id, { mark_seen: true, mark_read: true });
          n.is_seen = true;
          n.is_read = true;
        }
      }
    }
  }

  @action public clear() {
    if (this.subscription) {
      this.subscription.cancel();
    }
    this.inProgressTransferId = null;
    this.subscription = null;
    this.feed = null;
    this._notifications = [];
    this.cancelTransferSuccessful = false;
    this.cancelingTransferId = null;
  }

  @action public resetPhoneTransactions() {
    this.inProgressTransferId = null;
    this.recentPhoneTransaction = null;
  }

  @action public resetTransferCancel() {
    this.cancelTransferSuccessful = false;
    this.cancelingTransferId = null;
  }

  private notification_type(verb: string) {
    switch (verb) {
      case "default_account_changed":
        return "settings";
      default:
        return "system";
    }
  }

  private format_message(verb: string, data: any) {
    switch (verb) {
      case "transfer_status":
        return `Transfer of $${data.amount.value} to ${data.identifier.value} ${data.status}`;
      case "transfer_received":
        return `Received $${data.amount.value} from ${data.sender}`;
      case "default_account_changed":
        return `The default account to receive funds for ${data.identifier.value} has been changed to another institution`;
      default:
        return "unknown notification";
    }
  }

  @action private async loadNotificationsInternal(subscribe: boolean) {
    const streaminfo = await notificationApi.getNotificationStream();

    const appid = streaminfo.data.appid;
    const apikey = streaminfo.data.apikey;
    const token = streaminfo.data.token;
    const feedName = streaminfo.data.feed;
    const userid = streaminfo.data.userid;
    const client = connect(apikey, token, appid);

    this.feed = client.feed(feedName, userid);

    const response: any = await this.feed.get({ limit: 30 });
    this._notifications = response.results.map((item: any) => {
      return {
        id: item.id,
        timestamp: item.activities[0].data.timestamp,
        type: this.notification_type(item.verb),
        msg: this.format_message(item.verb, item.activities[0].data),
        is_read: item.is_read,
        is_seen: item.is_seen,
        transfer_id: item.activities[0].data.transfer_id,
        data: item.activities[0].data,
        verb: item.verb,
      };
    });

    if (subscribe) {
      this.subscription = this.feed.subscribe(async () => {
        await this.loadNotificationsInternal(false);
      });
    }
  }
}

const notificationStore = new NotificationStore();
export default createContext(notificationStore);
