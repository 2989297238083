import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography, List, ListItem, ListItemText, Menu, MenuItem } from "@mui/material";

import { Account, Card } from "src/services/generatedApi";
import CardDisplay from "src/components/App/CardDisplay";

export const TransferCard = ({
  filterCards,
  filteredCards,
  setAccount,
  accounts,
  account,
  card,
  setCard,
}: {
  filterCards: (account: Account) => void;
  filteredCards: Card[] | undefined;
  setAccount: (acct: Account) => void;
  account: Account | undefined;
  accounts: Account[];
  card: Card | undefined;
  setCard: (card: Card) => void;
}) => {
  const [accountAnchorElement, setAccountAnchorElement] = React.useState<null | HTMLElement>(null);
  const [cardAnchorElement, setCardAnchorElement] = React.useState<null | HTMLElement>(null);
  const handleListAccountItemClick = (event: React.MouseEvent<HTMLElement>) => {
    setAccountAnchorElement(event.currentTarget);
  };

  const handleListCardItemClick = (event: React.MouseEvent<HTMLElement>) => {
    const element = filteredCards && filteredCards.length > 1 ? event.currentTarget : null;
    setCardAnchorElement(element);
  };

  const handleMenuAccountItemClick = (_event: React.MouseEvent<HTMLElement>, accountItem: Account) => {
    setAccount(accountItem);
    filterCards(accountItem);
    setAccountAnchorElement(null);
  };

  const handleMenuCardItemClick = (_event: React.MouseEvent<HTMLElement>, cardItem: Card) => {
    setCard(cardItem);
    setCardAnchorElement(null);
  };

  const handleClose = () => {
    setAccountAnchorElement(null);
    setCardAnchorElement(null);
  };

  return (
    <React.Fragment>
      {account && (
        <React.Fragment>
          <List component="nav">
            <ListItem
              button
              aria-haspopup="true"
              aria-controls="account-menu"
              aria-label="Checking account"
              onClick={handleListAccountItemClick}
            >
              <ListItemText
                primary={
                  <Typography component="p" variant="subtitle1" color="secondary.dark">
                    {account.name}
                  </Typography>
                }
              />
              <ListItemText style={{ textAlign: "right" }} primary={<ExpandMoreIcon />} />
            </ListItem>
          </List>
          <Menu
            id="account-menu"
            anchorEl={accountAnchorElement}
            keepMounted
            open={Boolean(accountAnchorElement)}
            onClose={handleClose}
          >
            {accounts.map((accountItem: Account, index: number) => (
              <MenuItem
                key={index}
                selected={accountItem.id === account.id}
                onClick={(event) => handleMenuAccountItemClick(event, accountItem)}
              >
                {accountItem.name}
              </MenuItem>
            ))}
          </Menu>
        </React.Fragment>
      )}
      {card && filteredCards && filteredCards.length > 1 && (
        <React.Fragment>
          <List component="nav">
            <ListItem
              button
              aria-haspopup="true"
              aria-controls="account-menu"
              aria-label="Bank Card"
              onClick={handleListCardItemClick}
            >
              <ListItemText
                primary={
                  <Typography component="p" variant="subtitle1" color="secondary.dark">
                    <CardDisplay card={card} />
                  </Typography>
                }
              />
              <ListItemText style={{ textAlign: "right" }} primary={<ExpandMoreIcon />} />
            </ListItem>
          </List>
          <Menu
            id="card-menu"
            anchorEl={cardAnchorElement}
            keepMounted
            open={Boolean(cardAnchorElement)}
            onClose={handleClose}
          >
            {filteredCards.map((cardItem: Card, index: number) => (
              <MenuItem
                key={index}
                selected={cardItem.uuid === card.uuid}
                onClick={(event) => handleMenuCardItemClick(event, cardItem)}
              >
                <CardDisplay card={cardItem} />
              </MenuItem>
            ))}
          </Menu>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
