import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { Grid, Typography, Fab } from "@mui/material";

import ico_list_2 from "src/assets/ico_list_2.svg";
import ico_settings_2 from "src/assets/ico_settings_2.svg";
import ico_handshake from "src/assets/ico_handshake.svg";
import { ROUTE_CONSTANTS } from "src/constants";

const { HISTORY_PAGE_PATH, SETTINGS_PATH, SIGN_OUT_PATH, NP_EVENT_DEMO } = ROUTE_CONSTANTS;

const AppBar = ({ appStyles, fi_ui_customization }: { appStyles: any; fi_ui_customization: any }) => {
  const navigate = useNavigate();

  const [loginType] = useState(window.localStorage.getItem("loginType"));

  return (
    <Grid container style={appStyles.appBar}>
      <Grid item style={{ flexGrow: 5, position: "relative" }}>
        <Typography style={{ ...appStyles.title, padding: "15px 0 8px 0" }}>Send money</Typography>
      </Grid>
      <Grid item style={{ flexGrow: 1 }}>
        <Fab
          aria-label="Settings"
          style={{ ...appStyles.fab, float: "right", marginRight: "10px" }}
          onClick={() => navigate(SETTINGS_PATH)}
        >
          <img src={ico_settings_2} alt="Settings icon" />
        </Fab>
        <Fab
          aria-label="History"
          style={{ ...appStyles.fab, float: "right", marginRight: "10px" }}
          onClick={() => navigate(HISTORY_PAGE_PATH)}
        >
          <img style={{ marginTop: "8px" }} src={ico_list_2} alt="History icon" />
        </Fab>
        {loginType == "sso" ? null : (
          <Fab
            aria-label="Log out"
            style={{ ...appStyles.fab2, float: "right", marginRight: "10px" }}
            onClick={() => navigate(SIGN_OUT_PATH)}
          >
            Log Out
          </Fab>
        )}
        {fi_ui_customization.event_demo && (
          <Fab
            aria-label="Event demo"
            style={{ ...appStyles.fab2, float: "right", margin: "-3px 10px 0 0" }}
            onClick={() => navigate(NP_EVENT_DEMO)}
          >
            <img style={{ marginTop: "8px" }} src={ico_handshake} alt="Handshake icon" />
          </Fab>
        )}
      </Grid>
    </Grid>
  );
};

export default AppBar;
